<template>
    <div>
        <v-card height="300" id="container" flat>

            <v-card-title class="d-flex align-center py-1">
                <h6 class="mr-auto">Connection Types</h6>
            </v-card-title>

            <v-divider></v-divider>

            <div v-if="connection_types.length">
                <v-card-text>
                    <apexchart type="bar" height="15" :options="chartOptions" :series="series"></apexchart>
                </v-card-text>
                <v-card-subtitle class="black--text text-caption">
                    <div v-for="(item, index) in series" :key="index" class="d-flex justify-space-between align-center">
                        <div>
                            <v-icon left x-small :color="colors[index]">mdi-circle</v-icon>
                            {{ item?.name }}
                        </div>
                        <div>
                            {{ `${item?.data}%` }}
                        </div>
                    </div>
                </v-card-subtitle>
            </div>

            <div v-else>
                <v-card height="255" flat class="d-flex align-center justify-center">
                    <div class="text-center py-10">
                        <v-icon x-large>mdi-alert-circle-outline</v-icon>
                        <p class="text-caption text-disabled">No data available</p>
                    </div>
                </v-card>
            </div>

        </v-card>


    </div>
</template>

<script>
export default {
    data() {
        return {
            series: [
                {
                    name: "Cellular Network - Unknown Generation",
                    code: 3,
                    data: []
                },
                {
                    name: "Cellular Network - 2G",
                    code: 4,
                    data: []
                },
                {
                    name: "Wifi",
                    code: 2,
                    data: []
                },
                {
                    name: "Cellular Network - 3G",
                    code: 5,
                    data: []
                },
                {
                    name: "Cellular Network - 4G",
                    code: 6,
                    data: []
                },
                {
                    name: "Ethernet",
                    code: 1,
                    data: []
                },
                {
                    name: "Unknown",
                    code: 0,
                    data: []
                }
            ],
            colors: ["blue", "purple", "red", "orange", "yellow", "green"],
            chartOptions: {
                chart: {
                    type: "bar",
                    stacked: true,
                    stackType: "100%",
                    sparkline: {
                        enabled: true,
                    },
                },
                colors: [
                    "#2196F3",
                    "#9c27b0",
                    "#F44336",
                    "#ff9800",
                    "#ffeb3b",
                    "#4CAF50",
                ],
                labels: ['App', 'Web'],
                plotOptions: {
                    bar: {
                        horizontal: true,
                    },
                },
                stroke: {
                    width: 1,
                    colors: ["#fff"],
                },

                xaxis: {
                    categories: ["Connection"],
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val.toFixed(2) + "%";
                        },
                    },
                },
            },
        }
    },
    props: {
        campaign: {
            type: String,
            validator: function (value) {
                return Number(value);
            }
        },
    },

    watch: {
        connection_types: {
            handler: function (val) {

                const totalDocCount = val.reduce((acc, item) => acc + item.doc_count, 0);

                // Update data array in mappedDataSeries with percentage of total
                this.series.forEach(series => {
                    const index = val.findIndex(item => item.key === series.code);
                    if (index !== -1) {
                        const percentage = ((val[index].doc_count / totalDocCount) * 100).toFixed(1);
                        series.data = [parseFloat(percentage)];
                    } else {
                        series.data = [0.0];
                    }
                });

                this.series.sort((a, b) => b.data[0] - a.data[0]);
            },
            deep: true
        },
    },
    mounted() {
        this.getConnectionTypes();
    },

    computed: {
        connection_types() {
            return this.$store.state.dsp.connection_types
        }
    },

    methods: {
        //Get top carriers
        async getConnectionTypes() {

            this.loading = true;

            try {

                let body = {
                    "campaign": this.campaign,
                    "body": {
                        "size": 0,
                        "query": {},
                        "aggs": {
                            "test_histogram": {
                                "terms": {
                                    "field": "r_device.connectiontype",
                                    "size": 10
                                }
                            }
                        }
                    }
                }

                await this.$store.dispatch("dsp/getConnectionTypes", body);

            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
    }
}
</script>