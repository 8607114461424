<template>
  <div ref="campaignstatistics" v-if="campaign_statistics && dsp_campaign">

    <updatevtr @dismiss="dismissFn($event)" @updateViews="updateViews($event)"
      @updateEngagement="updateEngagement($event)" :campaign="campaign_statistics" :dialog="dialog" />
    <ShowBanner :dialog="bannerShow" :banner="bannerDetails" @dismiss="dismissFn($event)" :width="width" />

    <BillingMetaData :isBillingModalOpen="billingDialog" v-if="superAdmin" :campaign="campaign_statistics"
      @dismiss="closeModal($event)"></BillingMetaData>

    <CampaignAppInstalls v-if="superAdmin" :isInstallsModalOpen="isInstallsModalOpen" :campaign="campaign_statistics" @dismiss="closeModal($event)"></CampaignAppInstalls>

    <div class="my-4 d-flex align-center justify-space-between">
      <div>
        <v-btn small color="grey" outlined @click="$router.back()" class="mb-5">
          <v-icon left> mdi-arrow-left </v-icon>
          Back
        </v-btn>

        <h2>
          {{
            campaign_statistics.campaign.name
            ? campaign_statistics.campaign.name
            : ""
          }}
        </h2>
        <span class="overline">
          <v-chip v-if="campaignStatus === null" color="grey lighten-5" text-color="grey" class="font-weight-bold" small>
            <v-icon x-small left>mdi-circle</v-icon>Incomplete
          </v-chip>
          <v-chip v-else-if="campaignStatus == 'Active'" color="green lighten-5" text-color="success"
            class="font-weight-bold" small>
            <v-icon x-small left>mdi-circle</v-icon>{{ campaignStatus }}
          </v-chip>
          <v-chip v-else-if="campaignStatus == 'Paused'" color="grey lighten-5" text-color="grey" class="font-weight-bold"
            small>
            <v-icon x-small left>mdi-circle</v-icon>{{ campaignStatus }}
          </v-chip>
          <v-chip v-else-if="campaignStatus == 'In Review'" color="orange lighten-5" text-color="warning"
            class="font-weight-bold" small>
            <v-icon x-small left>mdi-circle</v-icon>{{ campaignStatus }}
          </v-chip>
          <v-chip v-else-if="campaignStatus == 'Completed'" color="cyan lighten-5" text-color="cyan"
            class="font-weight-bold" small>
            <v-icon x-small left>mdi-circle</v-icon>{{ campaignStatus }}
          </v-chip>
          <v-chip v-else color="red lighten-5" text-color="error" class="font-weight-bold" small>
            <v-icon x-small left>mdi-circle</v-icon>{{ campaignStatus }}
          </v-chip>
        </span>

        <span class="overline ml-2" v-if="superAdmin">
          Status: {{ campaign_statistics.campaign.status }}
        </span>
        <span class="overline ml-2">
          <!-- replace(/-/g, "/") is used to replace all - with / in the date string to fix safari date issue -->
          <v-icon small class="mb-1"> mdi-clock-outline </v-icon>
          Start:
          {{
            new Date(
              campaign_statistics.campaign.activate_time.replace(/-/g, "/")
            ).toLocaleDateString("en-us", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })
          }}
          | End:
          {{
            new Date(
              campaign_statistics.campaign.expire_time.replace(/-/g, "/")
            ).toLocaleDateString("en-us", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })
          }}
        </span>
      </div>

      <div class="d-flex">
        <!--Other action buttons-->
        <div class="d-flex">

          <BtnDownloadLeads v-if="isLead" :url="campaign_statistics.leadgenreport" :is-disabled="isDisabled" class="mr-2">
            
          </BtnDownloadLeads>

          <v-btn v-if="showUpload() && superAdmin" @click="showDialog" color="blue-grey ml-3" depressed
            class="white--text mr-2">
            Update Views
            <v-icon right dark> mdi-cloud-upload </v-icon>
          </v-btn>
          <v-btn v-if="showEngagementButton() && superAdmin" @click="showDialog" color="blue-grey ml-3" depressed
            class="white--text mr-2">
            Update Engagement
            <v-icon right dark> mdi-cloud-upload </v-icon>
          </v-btn>
        </div>

        <!--Action menu-->
        <v-menu bottom offset-y open-on-hover class="ml-2">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark depressed v-bind="attrs" v-on="on">
              Actions <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="downloadPDF">
              <v-list-item-title><v-icon small left>mdi-download</v-icon> Download
                PDF</v-list-item-title>
            </v-list-item>
            <v-list-item @click="downloadExcel">
              <v-list-item-title><v-icon small left>mdi-download</v-icon> Download
                Excel</v-list-item-title>
            </v-list-item>
            <v-list-item @click="downloadCSV">
              <v-list-item-title><v-icon small left>mdi-download</v-icon> Download
                CSV</v-list-item-title>
            </v-list-item>

            <v-list-item v-if="superAdmin && showAppInstallsUpdateOption()" @click="openInstallsModal">
              <v-list-item-title><v-icon small class="me-2">mdi-tablet-cellphone</v-icon> Update App Installs</v-list-item-title>
            </v-list-item>

            <v-list-item @click.stop="drawer = !drawer">
              <v-list-item-title><v-icon small left>mdi-file-document-outline</v-icon> Change
                Logs</v-list-item-title>
            </v-list-item>
            <v-list-item @click="
              $router.push({
                name: 'edit-dsp-campaign',
                params: { id: campaign_statistics.campaign.id },
              })
              ">
              <v-list-item-title><v-icon small left>mdi-pencil</v-icon> Edit
                Campaign</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

    <section>
      <v-row>
        <v-col cols="12">
          <v-card outlined>
            <v-card-title class="d-flex align-center py-2">
              <div class="d-flex">
                <h5>Campaign Perfomance Overview</h5>
              </div>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text>
              <div class="mb-5">
                <v-row class="mb-2">
                  <v-col cols="8">
                    <v-row>
                      <v-col :cols="6" v-for="(item, index) in sortedFilteredMetrics" :key="item.text">
                        <v-card flat color="grey lighten-5">
                          <v-card-title class="card-title">
                            <div class="d-flex align-center justify-space-between">
                              <span class="text-h5 font-weight-bold">
                                <template v-if="item.text === 'Avg. CTR'">
                                  {{ item.value }}%
                                </template>
                                <template v-else>
                                  {{ item.value }}
                                </template>
                              </span>

                              <div class="d-flex flex-column align-end">
                                <v-chip small :color="cardsColorAndSign(item.text, item.value)?.color" label :text-color="cardsColorAndSign(item.text, item.value)?.textColor" class="font-weight-bold">
                                  <v-icon small left>
                                    {{
                                      cardsColorAndSign(item.text, item.value)
                                        ?.icon
                                    }}
                                  </v-icon>
                                  {{
                                    metricPercentageChange(
                                      item.text,
                                      item.value
                                    )
                                  }}
                                  %
                                </v-chip>
                                <span class="grey--text text-caption">Last {{ getLifetimeValue() }} days</span>
                              </div>
                            </div>
                          </v-card-title>
                          <v-card-subtitle class="overline pb-0">
                            {{ item.text }}
                          </v-card-subtitle>
                          <div id="container">
                            <apexchart ref="chart" type="area" :options="{
                              ...card_options,
                              colors: [seriesColors[index]],
                            }" :series="[item.seriesData]" height="50" />
                          </div>
                        </v-card>
                      </v-col>

                      <v-col v-if="superAdmin && !reachLoading || !showAppInstallsUpdateOption" cols="6">
                        <v-card flat color="grey lighten-5">
                          <v-card-title class="card-title">
                            <div class="d-flex align-center justify-space-between">
                              <span class="text-h5 font-weight-bold">
                                <template>
                                  {{campaign_reach?.totalReach}}
                                </template>
                              </span>

                              <div class="d-flex align-center justify-space-between align-end">
                                <!-- <v-chip small color="success" label text-color="white" class="font-weight-bold">
                                  <v-icon small left>
                                    mdi-trending-up
                                  </v-icon>
                                  10
                                  %
                                </v-chip> -->
                                  <v-icon small left>
                                    mdi-information
                                  </v-icon>
                                <span class="grey--text text-caption">Unique users reached</span>
                              </div>
                            </div>
                          </v-card-title>
                          <v-card-subtitle class="overline pb-0">
                            Reach
                          </v-card-subtitle>
                          <div id="container">
                            <apexchart ref="chart" type="area" :options="{
                              ...card_options,
                              colors: ['#4CAF50'],
                            }" :series="campaign_reach?.seriesData" height="50" />
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="4">
                    <v-card flat color="grey lighten-5">
                      <v-card-title class="pb-0">
                        <span class="grey--text text-body-1">Campaign Progress
                        </span>
                      </v-card-title>

                      <PacingGraph :data="_campaignProgress.progress" :colors="_campaignProgress.color" />
                    </v-card>
                  </v-col>
                </v-row>
              </div>

              <reporting-campaign-chart :stats="stats" :campaign_statistics="campaign_statistics"
                :select-charts="selectCharts" ref="reportCharts" @chartsRendered="downloadPDF"
                @dateRange="updateDateRange($event)" :campaign-objective="campaignObjective" :campaignType="campaignType"
                :campaignEndDate="_campaignEndDate" v-if="date_Leads" :date_Leads="date_Leads" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <DSPCampaignCharts :stats="stats" :campaign_statistics="campaign_statistics" :dateRange="filterDateRange"
            :campaign-objective="campaignObjective" :campaignType="campaignType" :campaignEndDate="_campaignEndDate"
            v-if="date_Leads" :date_Leads="date_Leads" />
        </v-col>
      </v-row>

      <v-row class="mt-3">
        <v-col cols="12">
          <v-card outlined>
            <v-card-title class="d-flex align-center py-2">
              <div class="d-flex">
                <h5>Campaign Details</h5>
              </div>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-divider></v-divider>

            <v-row>
              <v-col>
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td>
                          <span class="text-subtitle-2 font-weight-light">
                            Client:
                          </span>
                        </td>
                        <td>
                          <span class="text-body-2">
                            {{
                              campaign_statistics?.campaign?.campaign_attribute
                                ?.client_name
                            }}
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span class="text-subtitle-2 font-weight-light">
                            Campaign Type:
                          </span>
                        </td>
                        <td>
                          <span class="text-body-2 text-uppercase">
                            {{ campaignType }}
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span class="text-subtitle-2 font-weight-light">
                            Bid Strategy:
                          </span>
                        </td>
                        <td>
                          <span class="text-body-2 text-uppercase">
                            {{
                              dsp_campaign?.campaign?.conversion_type
                              ? dsp_campaign?.campaign?.conversion_type
                              : "CPM"
                            }}
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span class="text-subtitle-2 font-weight-light">
                            Campaign
                            {{
                              dsp_campaign?.campaign?.conversion_type
                              ? dsp_campaign?.campaign?.conversion_type
                              : "CPM"
                            }}:
                          </span>
                        </td>
                        <td>
                          <span class="text-body-2 text-uppercase">
                            {{
                              isFinite(
                                dsp_campaign?.campaign?.campaign_attribute
                                  ?.campaign_ecpm
                                  ? dsp_campaign?.campaign?.campaign_attribute
                                    ?.campaign_ecpm
                                  : "-"
                              )
                              ? parseFloat(
                                dsp_campaign?.campaign?.campaign_attribute
                                  ?.campaign_ecpm
                                  ? dsp_campaign?.campaign
                                    ?.campaign_attribute?.campaign_ecpm
                                  : "-"
                              ).toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                              : 0
                            }}
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span class="text-subtitle-2 font-weight-light">
                            Budget:
                          </span>
                        </td>
                        <td>
                          <span class="text-body-2 text-uppercase">
                            {{
                              parseFloat(
                                campaign_statistics.campaign.total_budget
                              ).toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                            }}
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span class="text-subtitle-2 font-weight-light">
                            Daily Budget:
                          </span>
                        </td>
                        <td>
                          <span class="text-body-2 text-uppercase">
                            {{
                              parseFloat(
                                campaign_statistics.campaign.budget_limit_daily
                                  ? campaign_statistics.campaign
                                    .budget_limit_daily
                                  : 0
                              ).toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                            }}
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span class="text-subtitle-2 font-weight-light">
                            Total Impressions:
                          </span>
                        </td>
                        <td>
                          <span class="text-body-2 text-uppercase">
                            {{
                              target_impressions == 0
                              ? "N/A"
                              : target_impressions.toLocaleString()
                            }}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>

              <v-col>
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td>
                          <span class="text-subtitle-2 font-weight-light">
                            Duration:
                          </span>
                        </td>
                        <td>
                          <span class="text-body-2"> {{ duration }} Days </span>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span class="text-subtitle-2 font-weight-light">
                            Campaign Objective:
                          </span>
                        </td>
                        <td>
                          <span class="text-body-2 text-uppercase">
                            {{
                              dsp_campaign.campaign?.campaign_attribute
                                ?.campaign_objective
                              ? dsp_campaign?.campaign?.campaign_attribute
                                ?.campaign_objective
                              : "-"
                            }}
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span class="text-subtitle-2 font-weight-light">
                            Branding:
                          </span>
                        </td>
                        <td>
                          <span class="text-body-2">
                            {{
                              dsp_campaign?.campaign?.campaign_attribute
                                ?.add_banner_branding
                              ? dsp_campaign?.campaign?.campaign_attribute
                                ?.branding_type
                              : "N/A"
                            }}
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span class="text-subtitle-2 font-weight-light">
                            Exchange:
                          </span>
                        </td>
                        <td>
                          <span class="text-body-2">
                            {{
                              campaign_statistics.campaign.exchanges
                              ? formatExchange(
                                campaign_statistics.campaign.exchanges
                              )
                              : "-"
                            }}
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span class="text-subtitle-2 font-weight-light">
                            Location:
                          </span>
                        </td>
                        <td>
                          <span class="text-body-2">
                            {{
                              dsp_campaign?.campaign?.campaign_attribute
                                ?.locations
                              ? dsp_campaign?.campaign?.campaign_attribute
                                ?.locations
                              : "Kenya"
                            }}
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span class="text-subtitle-2 font-weight-light">
                            Viewability:
                          </span>
                        </td>
                        <td>
                          <span class="text-body-2">
                            {{
                              dsp_campaign?.campaign?.campaign_attribute
                                ?.pre_bid_viewability
                              ? `${dsp_campaign?.campaign?.campaign_attribute?.pre_bid_viewability}%`
                              : "-"
                            }}
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span class="text-subtitle-2 font-weight-light">
                            Click Through:
                          </span>
                        </td>
                        <td>
                          <span class="text-body-2">
                            <span v-if="checkClickThrough(
                                  dsp_campaign.campaign?.campaign_attribute
                                    ?.cascade_click_url
                                )
                                "><a :href="dsp_campaign.campaign.ad_domain" target="_blank">{{
      checkClickThrough(
        dsp_campaign?.campaign?.campaign_attribute
          ?.cascade_click_url
      )
    }}</a></span>

                            <span v-else>N/A</span>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card outlined>
            <v-card-title class="py-2">
              <h5>Campaign Pretargets Overview</h5>
            </v-card-title>
            <v-divider></v-divider>

            <v-simple-table v-if="_targets">
              <template v-slot:default>
                <tbody>
                  <tr v-for="(item, key) in _targets" :key="item.id">
                    <td>
                      <span class="text-subtitle-2 font-weight-light">
                        {{ replaceUnderscore(key) }}:
                      </span>
                    </td>
                    <td>
                      <span class="text-body-2">
                        {{ item }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-card height="288" v-else flat class="d-flex align-center justify-center">
              <div class="text-center py-10">
                <v-icon x-large>mdi-target</v-icon>
                <p class="text-caption text-disabled">Targeting not set</p>
              </div>
            </v-card>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card outlined>
            <v-card-title class="py-2">
              <h5>
                Banner Preview
                {{
                  sample_banner
                  ? sample_banner?.width + " x " + sample_banner?.height
                  : ""
                }}
              </h5>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text v-if="sample_banner">
              <div class="d-flex">
                <div style="position: relative; margin: 0">
                  <iframe :src="sample_banner?.imageurl" :width="sample_banner?.width" :height="sample_banner.height"
                    frameborder="0"></iframe>
                  <a :href="sample_banner?.click_through_url" :style="{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: 'inline-block',
                    width: sample_banner?.width + 'px',
                    height: sample_banner?.height + 'px',
                    zIndex: 5,
                  }"></a>
                </div>

                <div class="ml-3">
                  <div>
                    <span class="text-subtitle-2 font-weight-light mr-2">Banner ID:</span>
                    <span class="text-body-2">{{ sample_banner?.id }}</span>
                  </div>

                  <div>
                    <span class="text-subtitle-2 font-weight-light mr-2">Size:</span>
                    <span class="text-body-2">{{ sample_banner?.width + " x " }}
                      {{ sample_banner?.height }}</span>
                  </div>

                  <div>
                    <span class="text-subtitle-2 font-weight-light mr-2">Impressions:</span>
                    <span class="text-body-2">{{
                      __bannerPreviewStats
                      ? __bannerPreviewStats?.impressions.toLocaleString()
                      : "N/A"
                    }}</span>
                  </div>

                  <div>
                    <span class="text-subtitle-2 font-weight-light mr-2">Clicks:</span>
                    <span class="text-body-2">{{
                      __bannerPreviewStats
                      ? __bannerPreviewStats?.clicks.toLocaleString()
                      : "N/A"
                    }}</span>
                  </div>

                  <div>
                    <span class="text-subtitle-2 font-weight-light mr-2">CTR (%):</span>
                    <span class="text-body-2">{{
                      __bannerPreviewStats ? __bannerPreviewStats?.ctr : "N/A"
                    }}</span>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card outlined>
            <v-card-title class="py-2">
              <h5>Banner Perfomance</h5>
            </v-card-title>

            <v-divider></v-divider>

            <v-row>
              <v-col cols="6">
                <v-card flat>
                  <v-card-title class="py-2">
                    <span class="text-subtitle-1">Impressions</span>
                  </v-card-title>

                  <v-divider></v-divider>

                  <BannerPerfomance type="Impressions" :data="__bannerImpressionsGraphData" />
                </v-card>
              </v-col>

              <v-col cols="6">
                <v-card flat>
                  <v-card-title class="py-2">
                    <span class="text-subtitle-1">CTR(%)</span>
                  </v-card-title>

                  <v-divider></v-divider>

                  <BannerPerfomance type="CTR(%)" :data="__bannerCTRGraphData" />
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col v-if="superAdmin" cols="6">

          <EnvironmentDistribution :campaign="this.$route.params.id" />

        </v-col>

        <v-col v-if="false" cols="6">
          <v-card height="350" id="container" flat>

            <v-card-title class="d-flex align-center py-1">
              <h5 class="mr-auto">Top Devices</h5>
            </v-card-title>

            <v-divider></v-divider>

            <TopDevices />
          </v-card>

        </v-col>

        <v-col v-if="superAdmin" cols="6">
          <OperatingSystem :campaign="this.$route.params.id" />
        </v-col>

        <v-col v-if="superAdmin" cols="6">
          <MakeDistribution :campaign="this.$route.params.id" />
        </v-col>

        <v-col v-if="superAdmin" cols="6">
          <ModelDistribution :campaign="this.$route.params.id" />
        </v-col>

        <v-col v-if="superAdmin" cols="6">
          <ConnectionType :campaign="this.$route.params.id" />
        </v-col>
        
        <v-col v-if="superAdmin" cols="6">
          <DeviceTypes :campaign="this.$route.params.id" />
        </v-col>

        <v-col v-if="superAdmin" cols="6">
          <BrowserDistribution :campaign="this.$route.params.id" />
        </v-col>

        <v-col v-if="superAdmin" cols="6">
          <DevicePriceComparison :campaign="this.$route.params.id" />
        </v-col>

        <v-col v-if="superAdmin" cols="6">
          <UsersBySimCount :campaign="this.$route.params.id" />
        </v-col>

        <v-col v-if="superAdmin" cols="6">
          <FastestGrowingModels :campaign="this.$route.params.id" />
        </v-col>

        <v-col v-if="superAdmin" cols="6">
          <TopApps :campaign="this.$route.params.id"/>
        </v-col>

        <v-col :cols="superAdmin ? 6 : 12">
          <v-card flat height="420">
            <v-card-title class="d-flex align-center py-1">
              <h5>Distribution</h5>
            </v-card-title>
            <v-divider></v-divider>
            <div id="basemap_id" ref="basemap"></div>
          </v-card>
        </v-col>

        <v-col v-if="superAdmin" cols="6">
          <TopCarriers :campaign="this.$route.params.id" />
        </v-col>

        <v-col v-if="superAdmin" cols="6">
          <CityWiseDistribution :campaign="this.$route.params.id"/>
        </v-col>

      </v-row>


      <v-row>
        <v-col cols>
          <template>
            <v-card outlined>
              <v-card-title class="d-flex align-center py-2">
                <div class="d-flex">
                  <h5>Banner Perfomance</h5>
                </div>
                <v-spacer></v-spacer>
              </v-card-title>


              <v-divider></v-divider>

              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        ID
                      </th>

                      <th class="text-left">
                        Banner
                      </th>

                      <!-- <th class="text-center">
                        Trend
                      </th> -->

                      <th class="text-left">
                        Size
                      </th>

                      <th class="text-left">
                        Type
                      </th>

                      <th class="text-left">
                        Impressions
                      </th>

                      <th class="text-left">
                        Clicks
                      </th>

                      <th class="text-left">
                        CTR (%)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in combined" :key="item?.name">
                      <td>{{ item.id }}</td>

                      <td>
                        <span class="font-weight-bold bannerid primary--text" @click="showBanner(item)">
                          {{ item.name }}
                        </span>
                      </td>

                      <!-- <td class="text-center">
                        <span>
                          <v-chip x-small color="success" label text-color="white" class="font-weight-bold">
                            <v-icon small left>
                              mdi-arrow-up
                            </v-icon>
                            10 %
                          </v-chip>
                        </span>
                      </td> -->

                      <td>
                        {{ item.width + `x` + item.height }}
                      </td>

                      <td>
                        <span class="text-capitalize">
                          {{ item.type }}
                        </span>
                      </td>

                      <td>
                        {{ item.impressions.toLocaleString() }}
                      </td>

                      <td>
                        {{ item.clicks }}
                      </td>

                      <td>
                        {{ item.ctr + `%` }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

            </v-card>
          </template>
        </v-col>
      </v-row>


      <v-row class="flex-column">
        <v-col>
          <v-card :loading="notesLoading" outlined>
            <v-card-title class="d-flex align-center py-2">
              <div class="d-flex">
                <h5>Campaign Notes</h5>
              </div>
              <v-spacer></v-spacer>
              <v-btn color="primary" depressed @click.stop="noteDialog = true">
                <v-icon left>mdi-plus</v-icon>
                Add Note
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-col v-if="_notes.length">
                  <v-sheet class="pa-4 grey lighten-5 mb-4" rounded outlined v-for="note in _notes" :key="note.id">
                    <v-row class="mb-3" align="center">
                      <v-list-item>
                        <v-list-item-avatar size="40" color="warning">
                          <span class="white--text text-uppercase">{{
                            note.user_name.charAt(0)
                          }}</span>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title class="font-weight-bold mt-0">{{
                            note.user_name
                          }}</v-list-item-title>

                          <v-list-item-subtitle class="text-caption">{{
                            new Date(note.created_at).toLocaleDateString(
                              "en-us",
                              {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              }
                            )
                          }}
                            {{
                              new Date(note.created_at).toLocaleTimeString(
                                "en-us",
                                {
                                  time: "short",
                                }
                              )
                            }}</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action v-if="_user.id === note.user_id">
                          <v-btn small icon @click="deleteNote(note.id)">
                            <v-icon small color="red">mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-row>

                    <p>
                      {{ note.note }}
                    </p>
                    <span>
                      <v-chip :color="getNoteTagColour(note.tag)" x-small>
                        {{ note.tag }}
                      </v-chip></span>
                  </v-sheet>
                </v-col>
                <v-col v-else class="text-center py-5">
                  <v-icon x-large>mdi-note-off-outline</v-icon>
                  <p class="text-caption">No Note Posted</p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card :loading="screenshotLoading" outlined>
            <v-card-title class="d-flex align-center py-2">
              <div class="d-flex">
                <h5>Campaign Screenshots</h5>
              </div>
              <v-spacer></v-spacer>

              <v-btn depressed @click="chooseFiles()">
                <input id="fileUpload" type="file" hidden accept="image/*" v-on:change="onFileChange" />
                <v-icon left>mdi-upload</v-icon>
                Upload Screenshot
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text>
              <v-row>
                <v-col v-if="_campaign_screenshots.length">
                  <v-row>
                    <template>
                      <v-col v-for="n in _campaign_screenshots" class="d-flex child-flex" cols="4" :key="n.path">
                        <v-hover v-slot="{ hover }">
                          <v-card style="cursor: pointer" flat :elevation="hover ? 12 : ''"
                            :class="{ 'on-hover': hover }">
                            <v-img @click="showImagePreview(n.screenshot_path)" :src="n.screenshot_path" aspect-ratio="1"
                              class="grey lighten-2">
                              <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-card>
                        </v-hover>
                      </v-col>
                    </template>
                  </v-row>
                </v-col>
                <v-col v-else class="text-center py-5">
                  <v-icon x-large>mdi-image-off-outline</v-icon>
                  <p class="text-caption">No Screenshots</p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </section>
    <!-- noteDialog -->
    <v-dialog v-model="noteDialog" max-width="480">
      <v-card>
        <v-toolbar flat color="grey lighten-3" light dense>
          <v-toolbar-title>Post Note</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn icon @click="noteDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-form ref="formNotes" v-model="note" lazy-validation>
          <v-card-text>
            <v-textarea v-model="noteText" filled :rules="noteRules" label="Note" hide-details="auto"
              class="mb-2"></v-textarea>

            <v-subheader class="px-0">Tags</v-subheader>
            <v-chip-group v-model="tags" mandatory active-class="primary--text">
              <v-chip v-for="tag in noteTags" :key="tag.value">
                {{ tag.text }}
              </v-chip>
            </v-chip-group>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="!note" color="success" depressed @click="submitNotes">
              Post Note
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- action log -->
    <v-navigation-drawer v-model="drawer" fixed temporary right width="50%" ref="logs">
      <v-card flat>
        <v-card-title class="py-2 pr-3 justify-space-between">
          <h6 class="text-uppercase">Change Log</h6>
          <v-btn icon @click="drawer = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-subtitle>
          <h4>
            {{
              campaign_statistics.campaign.name
              ? campaign_statistics.campaign.name
              : ""
            }}
          </h4>
          <span class="overline">
            <v-icon small class="mb-1"> mdi-clock-outline </v-icon>
            Start:
            {{
              new Date(
                campaign_statistics.campaign.activate_time
              ).toLocaleDateString("en-us", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })
            }}
            | End:
            {{
              new Date(
                campaign_statistics.campaign.expire_time
              ).toLocaleDateString("en-us", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })
            }}
          </span>
          <!-- <div class="caption">Creation Date: {{ new Date() }}</div> -->
        </v-card-subtitle>

        <action-log-table :headers="logHeaders" :items="logs"></action-log-table>
      </v-card>
    </v-navigation-drawer>

    <!-- Screenshot dialog -->
    <v-dialog max-width="60%" v-model="openScreenShotModal" ref="screenshots">
      <v-card :loading="screenshotLoading">
        <v-card-title>
          <v-btn v-if="!screenshotLoading" @click="deleteScreenshot(selectedScreenshot)" outlined small color="error">
            <v-icon size="20">mdi-delete</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider class="mb-5"></v-divider>

        <v-card-text>
          <v-img contain :max-height="dimensions?.height" :src="selectedScreenshot"></v-img>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ReportingCampaignChart from "@/components/Programmatic/ReportingCampaignChart.vue";
// import BtnUploadLeads from "../../components/Btns/BtnUploadLeads.vue";
import BtnDownloadLeads from "../../components/Btns/BtnDownloadLeads.vue";
import Updatevtr from "../../components/Programmatic/reporting/Updatevtr.vue";
import ShowBanner from "@/components/Programmatic/ShowBanner.vue";
import ActionLogTable from "@/components/Tables/ActionLogTable.vue";
import BillingMetaData from "../../components/Programmatic/BillingMetaData.vue";
import DSPCampaignCharts from "../../components/Programmatic/DSPCampaignCharts.vue";
import PacingGraph from "../../components/Programmatic/reporting/PacingGraph.vue";
import BannerPerfomance from "../../components/Programmatic/reporting/BannerPerfomance.vue";
import TopDevices from "../../components/Analytics/TopDevices.vue";
import OperatingSystem from "../../components/Analytics/OperatingSystem.vue";
import TopApps from "../../components/Analytics/TopApps.vue";
import TopCarriers from "../../components/Analytics/TopCarriers.vue";
import CityWiseDistribution from "../../components/Analytics/CityWiseDistribution.vue";
import EnvironmentDistribution from "../../components/Analytics/EnvironmentDistribution.vue";
import ConnectionType from "../../components/Analytics/ConnectionType.vue";
import DeviceTypes from "../../components/Analytics/DeviceTypes.vue";
import MakeDistribution from "../../components/Analytics/MakeDistribution.vue";
import ModelDistribution from "../../components/Analytics/ModelDistribution.vue";
import BrowserDistribution from "../../components/Analytics/BrowserDistribution.vue";
import DevicePriceComparison from "../../components/Analytics/DevicePriceComparison.vue";
import UsersBySimCount from "../../components/Analytics/UsersBySimCount.vue";
import FastestGrowingModels from "../../components/Analytics/FastestGrowingModels.vue";
import CampaignAppInstalls from "../../components/Programmatic/CampaignAppInstalls.vue";
//Map
import VectorMap from "jsvectormap";
import "jsvectormap/dist/maps/world";

import { serialize } from "object-to-formdata";

export default {
  components: {
    ReportingCampaignChart,
    BtnDownloadLeads,
    Updatevtr,
    ShowBanner,
    ActionLogTable,
    BillingMetaData,
    DSPCampaignCharts,
    PacingGraph,
    BannerPerfomance,
    TopDevices,
    OperatingSystem,
    TopApps,
    TopCarriers,
    CityWiseDistribution,
    EnvironmentDistribution,
    ConnectionType,
    DeviceTypes,
    MakeDistribution,
    ModelDistribution,
    BrowserDistribution,
    DevicePriceComparison,
    UsersBySimCount,
    FastestGrowingModels,
    CampaignAppInstalls
  },
  data() {
    return {
      //Map Reporting
      isInstallsModalOpen: false,
      map: {},
      map_countries: [],
      campaign_target: null,
      reachLoading: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dates: [],
      billingDialog: false,
      openScreenShotModal: false,
      selectedScreenshot: null,
      isDisabled: false,
      drawer: null,
      startDate: "",
      item: null,
      endDate: "",
      dialog: false,
      bannerShow: false,
      startMonth: "",
      endMonth: "",
      startYear: "",
      endYear: "",
      modal: false,
      dateMenu: false,
      bannerDetails: {},
      dimensions: {},
      banners: [],
      stats: [],
      screenshot: [],
      note: true,
      noteRules: [
        (v) => !!v || "Note is required",
        (v) => v?.length >= 5 || "Note must be more than 5 characters",
      ],
      noteText: "",
      tags: [0],
      noteTags: [
        { text: "none", value: "none" },
        { text: "start notes", value: "start" },
        { text: "progress notes", value: "progress" },
        { text: "end notes", value: "end" },
      ],
      noteDialog: false,
      notesLoading: false,
      screenshotLoading: false,
      width: "unset",
      search: "",
      apexLoading: false,
      imgdata: "",
      report: "",
      previewImage: [],
      hide: false,
      chart_items: [
        "Impressions",
        "Spend",
        "Clicks",
        "CTR",
        "CPM",
        "Leads",
        "CPL",
        "CPC",
        "Views",
        "VTR",
        "CPV",
        "ER",
        "Engagements",
        "Reach",
        "CPE",
        "Frequency",
        "CPA",
        "CPI",
        "Installs",
        "Acquisitions",
      ],
      headers: [
        { text: "ID", align: "start", value: "banner_id" },
        { text: "Name", align: "start", value: "banner.name" },
        { text: "Size", align: "start", value: "banner.width" },
        { text: "Impressions", align: "start", value: "impressions" },
        { text: "CTR", align: "start", value: "ctr" },
      ],
      bannerHeaders: [
        { text: "ID", align: "start", value: "id" },
        { text: "Banner", align: "start", value: "name" },
        { text: "Size", align: "start", value: "width" },
        { text: "Type", align: "start", value: "type" },
        { text: "Impressions", align: "start", value: "impressions" },
        { text: "Clicks", align: "start", value: "clicks" },
        { text: "CTR", align: "start", value: "ctr" },
      ],
      logHeaders: [
        { text: "Date", value: "date", width: "20%" },
        {
          text: "Field Name",
          value: "field",
          width: "20%",
        },
        {
          text: "Old",
          value: "old",
          width: "20%",
        },
        {
          text: "New",
          value: "new",
          width: "20%",
        },
        { text: "Changed By", value: "user", width: "20%" },
      ],
      creatives_data: [],
      filterDateRange: [],

      filterDataBasedOnDate: [
        {
          text: "Total Impressions",
          value: 0,
        },
        {
          text: "Total Clicks",
          value: 0,
        },
        {
          text: "Avg. CTR",
          value: 0,
        },
        {
          text: "Total Spend",
          value: 0,
        },
        {
          text: "Avg. CPM",
          value: 0,
        },
        {
          text: "Avg. CPC",
          value: 0,
        },
      ],
      totalLeads: "",
      date_Leads: Object,
      card_options: {
        chart: {
          height: 30,
          width: "100%",
          type: "area",
          parentHeightOffset: 0,
          toolbar: { show: false },
          sparkline: { enabled: true },
        },
        tooltip: { enabled: false },
        markers: {
          colors: "transparent",
          strokeColors: "transparent",
        },
        grid: { show: false },
        colors: [this.color],
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 0.8,
            opacityFrom: 0.6,
            opacityTo: 0.1,
          },
        },
        dataLabels: { enabled: false },
        stroke: {
          width: 2,
          curve: "smooth",
        },
        xaxis: {
          show: true,
          lines: { show: false },
          labels: { show: false },
          stroke: { width: 0 },
          axisBorder: { show: false },
        },
        yaxis: {
          stroke: { width: 0 },
          show: false,
        },
      },
      impressionsSeries: [],
      clicksSeries: [],
      spendSeries: [],
      cpmSeries: [],
      ctrSeries: [],
      cpcSeries: [],
      leadsSeries: [],
      cplSeries: [],
      seriesColors: [
        "#80CBC4",
        "#ffb01a",
        "#008ffb",
        "#50e7a6",
        "#FFC300",
        "#C70039",
        "#900C3F",
        "#581845",
      ],
      engagementSeries: [],
      cpeSeries: [],
      erSeries: [],
      setClicks: "",
      setCPC: "",
      budget: "",
      setCPM: "",
      setCTR: "",
      setImpressions: "",
    };
  },
  methods: {
    findById(array, value) {
      for (let i = 0; i < array.length; i++) {
        const obj = array[i];
        if (obj.id === value) {
          return obj;
        }
        if (obj.children) {
          const childMatch = this.findById(obj.children, value);
          if (childMatch) {
            return childMatch;
          }
        }
      }
      // return [];
    },
    populateCountries() {
      const isoAlpha2Lookup = this.countries.reduce((lookup, country) => {
        lookup[country["ISO3166-1-Alpha-3"]] = country["ISO3166-1-Alpha-2"];
        return lookup;
      }, {});
      const iso3_countries =
        this.dsp_campaign?.campaign?.target.country?.split(",");
      this.map_countries = iso3_countries
        .map((isoAlpha3) => isoAlpha2Lookup[isoAlpha3])
        .filter(Boolean);
    },
    async getStaticData() {
      try {
        await this.$store.dispatch("dsp/getStaticDspData");
      } catch (error) {
        this.error = error;
      }
    },
    showImagePreview(image) {
      const img = new Image();

      img.src = image;

      img.onload = () => {
        const dimensions = {
          width: img.width,
          height: img.height,
        };

        // Assuming you want to display the dimensions in your Vue component
        this.dimensions = dimensions;

        //open modal
        this.openScreenShotModal = true;

        //set selected screenshot
        this.selectedScreenshot = image;
      };
    },
    //delete screenshot
    async deleteScreenshot(screenshot) {
      let body = {
        campaignID: this.$route.params.id,
        path: screenshot,
      };

      try {
        this.screenshotLoading = true;

        await this.$store.dispatch("dsp/deleteCampaignScreenshot", body);

        this.screenshotLoading = false;

        this.$notify({
          title: "Screenshot deleted successfully!",
          group: "success",
        });

        //close modal
        this.openScreenShotModal = false;

        //fetch screenshots
        await this.getCampaignScreenshots();
      } catch (error) {
        this.$notify({
          title: error?.message,
          group: "errors",
        });
      } finally {
        this.screenshotLoading = false;
      }
    },
    calculateRangeLeadsCount(range) {
      if (range) {
        let sum = 0;
        const data = this.campaign_statistics.sum_date_leads;
        for (const date in data) {
          if (Object.prototype.hasOwnProperty.call(data, date)) {
            if (date >= range[0] && date <= range[1]) {
              sum += data[date];
            }
          }
        }
        this.totalLeads = sum;
      }
    },
    updateDateRange(range) {
      this.filterDateRange = range;

      this.calculateRangeLeadsCount(range);

      let startDate = range[0];
      let endDate = range[1];

      let data = this.stats.filter((item) => {
        return item.date >= startDate && item.date <= endDate;
      });

      let totalImpressions = 0;
      let totalClicks = 0;
      let totalSpend = 0;

      data.forEach((item) => {
        totalImpressions += item.total_impressions;
        totalClicks += item.total_clicks;
        totalSpend += parseFloat(item.total_spend);
      });

      //update filter data
      this.filterDataBasedOnDate[0].value = totalImpressions;
      this.filterDataBasedOnDate[1].value = totalClicks;
      this.filterDataBasedOnDate[2].value =
        (totalClicks / totalImpressions) * 100;
      this.filterDataBasedOnDate[3].value = totalSpend;
      this.filterDataBasedOnDate[4].value =
        (totalSpend / totalImpressions) * 1000;
      this.filterDataBasedOnDate[5].value = totalSpend / totalClicks;

      //format spend average cpm,ctr and cpc to two decimal places dont inlude impressions and clicks
      this.filterDataBasedOnDate.forEach((item) => {
        if (item.text !== "Total Impressions" && item.text !== "Total Clicks") {
          item.value = item.value.toFixed(2);
        }
      });

      //Update CardCharts Data+ Dates
      let filteredStats = this.stats.filter((item) => {
        let itemDate = item.date;
        return itemDate >= startDate && itemDate <= endDate;
      });

      this.impressionsSeries = {
        name: "Impressions",
        data: filteredStats.map((item) => item.total_impressions),
      };

      this.clicksSeries = {
        name: "Clicks",
        data: filteredStats.map((item) => item.total_clicks),
      };

      this.spendSeries = {
        name: "Spend",
        data: filteredStats.map((item) => parseFloat(item.total_spend)),
      };

      this.cpmSeries = {
        name: "CPM",
        data: filteredStats.map((item) => parseFloat(item.average_cpm)),
      };

      this.ctrSeries = {
        name: "CTR",
        data: filteredStats.map((item) => parseFloat(item.average_ctr)),
      };

      this.cpcSeries = {
        name: "CPC",
        data: filteredStats.map((item) =>
          item.average_cpc
            ? parseFloat(item.average_cpc)
            : item.total_clicks !== 0 && item.total_spend
              ? parseFloat(item.total_spend) / parseFloat(item.total_clicks)
              : null
        ),
      };

      this.leadsSeries = {
        name: "Leads",
        data: this.campaign_statistics.sum_date_leads
          ? Object.keys(this.campaign_statistics.sum_date_leads)
            .filter((date) => date >= startDate && date <= endDate)
            .map((date) => this.campaign_statistics.sum_date_leads[date])
          : [],
      };

      this.cplSeries = {
        name: "CPL",
        data: filteredStats.map((item) => {
          const itemDate = item.date;
          const dateLead = this.campaign_statistics.sum_date_leads[itemDate];
          if (dateLead && item.total_spend !== 0) {
            return parseFloat(item.total_spend) / parseFloat(dateLead);
          } else {
            return 0;
          }
        }),
      };

      this.engagementSeries = {
        name: "Engagements",
        data: filteredStats.map((stat) =>
          this.campaign_statistics.engagements.events_by_date[stat.date]
            ? this.campaign_statistics.engagements.events_by_date[stat.date]
              .length
            : 0
        ),
      };

      this.cpeSeries = {
        name: "CPE",
        data: filteredStats.map((stat) => {
          const totalSpend = parseFloat(stat.total_spend);
          const dateEngagements = this.campaign_statistics.engagements
            .events_by_date[stat.date]
            ? this.campaign_statistics.engagements.events_by_date[stat.date]
            : 0;
          const sumOfEngagements = dateEngagements ? dateEngagements.length : 0;
          return sumOfEngagements > 0 ? totalSpend / sumOfEngagements : 0;
        }),
      };

      this.erSeries = {
        name: "Avg. ER",
        data: filteredStats.map((stat) => {
          const totalImpressions = parseFloat(stat.total_impressions);
          const dateEngagements = this.campaign_statistics.engagements
            .events_by_date[stat.date]
            ? this.campaign_statistics.engagements.events_by_date[stat.date]
            : 0;
          const sumOfEngagements = dateEngagements ? dateEngagements.length : 0;
          return sumOfEngagements > 0
            ? (sumOfEngagements / totalImpressions) * 100
            : 0;
        }),
      };

      this.card_options = {
        ...this.card_options,
        xaxis: {
          type: "datetime",
          categories: filteredStats.map((item) => item.date),
          labels: {
            format: "MMM dd",
          },
        },
      };
    },
    openBillingDrawer() {
      this.billingDialog = true;
    },
    closeModal() {
      this.isInstallsModalOpen = false;
      this.billingDialog = false;
    },
    submitNotes() {
      this.$refs.formNotes.validate();

      const note = {
        note: this.noteText,
        tag: this.noteTags[this.tags].value,
      };
      this.postNote(note);
    },
    deleteNote(id) {
      this.deleteNotes(id);
    },

    replaceUnderscore(string) {
      return string.replace(/_/g, " ");
    },
    async showBanner(item) {
      this.bannerShow = !this.bannerShow;
      this.bannerDetails = await item;
    },
    getTarget(target_id) {
      if (this.dsp_targets.length) {
        const target_list = this.dsp_targets;
        const campaign_target = target_list.find(
          (target) => target.id == target_id
        ).name;
        return campaign_target;
      } else {
        return "-";
      }
    },
    formatExchange(exchange) {
      if (exchange === "google") {
        return "double click";
      } else {
        return exchange;
      }
    },
    checkClickThrough(cascade_state) {
      if (cascade_state == 1) {
        //return the first 10 characters of the click through url
        return this.dsp_campaign.campaign.ad_domain.substring(0, 20) + "...";
      } else {
        return false;
      }
    },
    showUpload() {
      if (this.campaign_statistics) {
        if (
          this.campaign_statistics?.campaign?.campaign_attribute
            ?.campaign_type === "video"
        ) {
          return true;
        }
        return false;
      }
    },
    showEngagementButton() {
      if (this.campaign_statistics) {
        if (
          this.campaign_statistics?.campaign?.campaign_attribute
            ?.campaign_type === "engagement"
        ) {
          return true;
        }
        return false;
      }
    },

    showAppInstallsUpdateOption() {
      if (this.campaign_statistics) {
        if (
          this.campaign_statistics?.campaign?.campaign_attribute
            ?.campaign_objective === "installs"
        ) {
          return true;
        }
        return false;
      }
    },

    showDialog() {
      this.dialog = true;
    },
    openInstallsModal() {
      this.isInstallsModalOpen = true;
    },
    dismissFn() {
      this.dialog = false;
      this.bannerShow = false;
    },

    chooseFiles() {
      document.getElementById("fileUpload").click();
    },

    async onFileChange(e) {
      this.file = e.target.files[0];

      let requestBody = {
        file: this.file,
      };

      const serializedBody = serialize(requestBody);

      const body = {
        body: serializedBody,
        campaignID: this.$route.params.id,
      };

      try {
        this.screenshotLoading = true;

        await this.$store.dispatch("dsp/uploadScreenshots", body);

        this.$notify({
          title: "Screenshot uploaded successfully!",
          group: "success",
        });

        //fetch screenshots
        await this.$store.dispatch(
          "dsp/getCampaignScreenshots",
          this.$route.params.id
        );
      } catch (error) {
        this.$notify({
          title: error?.message,
          group: "errors",
        });
      } finally {
        this.screenshotLoading = false;
      }
    },

    async postNote(note) {
      const body = {
        campaign_id: this.$route.params.id,
        note: note.note,
        tag: note.tag,
      };
      try {
        this.notesLoading = true;
        await this.$store.dispatch("dsp/postNote", body);

        this.$refs.formNotes.reset();
        this.noteDialog = false;
        this.notesLoading = false;
        this.getCampaignNotes();
        this.$notify({
          title: "Note posted successfully!",
          group: "success",
        });
      } catch (error) {
        this.$notify({
          title: error,
          group: "errors",
        });
      } finally {
        this.notesLoading = false;
      }
    },
    async deleteNotes(id) {
      try {
        this.notesLoading = true;
        await this.$store.dispatch("dsp/deleteNote", id);
        this.notesLoading = false;
        this.getCampaignNotes();

        this.$notify({
          title: "Note deleted successfully!",
          group: "success",
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.notesLoading = false;
      }
    },
    async getCampaignNotes() {
      try {
        this.notesLoading = true;
        await this.$store.dispatch(
          "dsp/getCampaignNotes",
          this.$route.params.id
        );
        this.notesLoading = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.notesLoading = false;
      }
    },

    async fetchCampaignReachData() {
      this.reachLoading = true;

      try {

        await this.$store.dispatch("dsp/getCampaignReach",this.$route.params.id);

      } catch (error) {
        console.log(error);
      } finally {
        this.reachLoading = false;
      }
    },

    async getCampaignScreenshots() {
      try {
        this.screenshotLoading = true;

        await this.$store.dispatch(
          "dsp/getCampaignScreenshots",
          this.$route.params.id
        );

        this.screenshotLoading = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.screenshotLoading = false;
      }
    },

    async updateViews(body) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/updateCampaignViews", body);
        this.$store.commit("makingApiRequest", false);

        this.$notify({
          title: "Views updated successfully!",
          group: "success",
        });
      } catch (error) {
        this.$notify({
          title: error,
          group: "errors",
        });
        this.$store.commit("makingApiRequest", false);
      }
    },
    async updateEngagement(body) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/updateCampaignEngagement", body);
        this.$store.commit("makingApiRequest", false);

        this.$notify({
          title: "Engagement updated successfully!",
          group: "success",
        });
      } catch (error) {
        this.$notify({
          title: error.message,
          group: "errors",
        });
        this.$store.commit("makingApiRequest", false);
      }
    },
    getColor(status) {
      if (status === "Paid") return "green";
      else if (status === "Credited") return "orange";
      else return "red";
    },
    getNoteTagColour(tag) {
      if (tag == "start") return "blue lighten-4";
      else if (tag == "progress") return "orange lighten-4";
      else if (tag == "end") return "green lighten-4";
      else return "grey lighten-2";
    },
    downloadLeads() {
      if (this.campaign_statistics.leadgenreport === null) {
        this.isDisabled = !this.isDisabled;
      }
    },
    bestPerformingBanner(value) {
      if (this.isLead || this.isEngagement) {
        return value.impressions > 100000;
      } else return value.ctr > 0.1;
    },
    worstPerformingBanner(value) {
      if (this.isLead || this.isEngagement) {
        return value.impressions < 100000;
      } else return value.ctr < 0.1;
    },

    async downloadLeadGenReport(value) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/downloadLeadGen", value);
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },

    async downloadPDF() {
      html2canvas(this.$refs.campaignstatistics).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "px",
          format: "a4",
          hotfixes: ["px_scaling"],
        });

        const pageHeight = pdf.internal.pageSize.getHeight();
        const pageWidth = pdf.internal.pageSize.getWidth();
        const padding = 20;

        const imgheight = (canvas.height * pageWidth) / canvas.width;
        const pagecount = Math.ceil(imgheight / pageHeight);

        /* add initial page with padding */
        pdf.addImage(
          imgData,
          "PNG",
          padding,
          -padding,
          pageWidth - 2 * padding,
          0
        );

        /* add extra pages if the div size is larger than an A4 size */
        if (pagecount > 0) {
          var i = 1;
          while (i !== pagecount) {
            pdf.addPage();
            pdf.addImage(
              imgData,
              "PNG",
              padding,
              -(i * pageHeight) - padding,
              pageWidth - 2 * padding,
              0
            );
            i++;
          }
        }

        pdf.save(`${this.campaign_statistics.campaign.name}.pdf`);
      });
    },

    async downloadExcel() {
      let req_body;
      if (this.filterDateRange[0] && this.filterDateRange[1]) {
        req_body = {
          campaign_id: this.$route.params.id,
          start_date: this.filterDateRange[0],
          end_date: this.filterDateRange[1],
          format: "excel"
        };
      } else {
        req_body = {
          campaign_id: this.$route.params.id,
          start_date: this.dates[0],
          end_date: this.dates[1],
          format: "excel"
        };
      }
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/downloadCustomReport", req_body);
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }

      //Download File
      const file_url = this.$store.state.dsp.dsp_custom_report;
      window.open(file_url, "_blank");
    },

    async downloadCSV() {
      let req_body;
      if (this.filterDateRange[0] && this.filterDateRange[1]) {
        req_body = {
          campaign_id: this.$route.params.id,
          start_date: this.filterDateRange[0],
          end_date: this.filterDateRange[1],
          format: "csv"
        };
      } else {
        req_body = {
          campaign_id: this.$route.params.id,
          start_date: this.dates[0],
          end_date: this.dates[1],
          format: "csv"
        };
      }
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/downloadCustomReport", req_body);
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }

      //Download File
      const file_url = this.$store.state.dsp.dsp_custom_report;
      window.open(file_url, "_blank");
    },

    getStartDates(date) {
      this.startDate = new Date(date).getDate();
      this.startMonth = new Date(
        this.campaign_statistics.campaign.activate_time
      ).getMonth();
      this.startYear = new Date(date).getFullYear();
      if (!this.dates.includes(new Date(date).toISOString().substring(0, 10))) {
        this.dates.push(new Date(date).toISOString().substring(0, 10));
      }
    },
    getEndDates(date) {
      this.endDate = new Date(date).getDate();
      this.endMonth = new Date(date).getMonth();
      this.endYear = new Date(date).getFullYear();
      if (!this.dates.includes(new Date(date).toISOString().substring(0, 10))) {
        this.dates.push(new Date(date).toISOString().substring(0, 10));
      }
    },
    async singleCampaignStats(id) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/singleCampaignStatistics", id);
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
      }
    },
    //Get Single Campaign
    async getCampaignData() {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch(
          "dsp/getOneDspCampaign",
          this.$route.params.id
        );
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
      }
    },
    async getDspTargets() {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/getAllTargets");
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
      }
    },

    capitalizeFirstLetter(string) {
      if (string) {
        const capitalized = string.charAt(0).toUpperCase() + string.slice(1);
        return capitalized;
      }
      return "-";
    },
    initialStart() {
      this.getStartDates(this.campaign_statistics.campaign.activate_time);
      this.getEndDates(this.campaign_statistics.campaign.expire_time);
      //Creatives
      for (
        let i = 0;
        i < this.campaign_statistics.banner_performance.length;
        i++
      ) {
        if (
          !this.creatives_data.includes(
            this.campaign_statistics.banner_performance[i]
          )
        ) {
          this.creatives_data.push(
            this.campaign_statistics.banner_performance[i]
          );
        }
      }
    },
    formatValue(value) {
      if (typeof value !== "string") {
        return value;
      }

      let numericalValue = parseFloat(value.replace(/,/g, ""));
      if (!isNaN(numericalValue)) {
        let suffixes = ["", "K", "M", "B", "T"];
        let suffixIndex = 0;
        while (numericalValue >= 1000 && suffixIndex < suffixes.length - 1) {
          numericalValue /= 1000;
          suffixIndex++;
        }
        let formattedValue =
          numericalValue < 10
            ? numericalValue.toFixed(0)
            : numericalValue.toFixed(0);
        return formattedValue + suffixes[suffixIndex];
      }
    },
    getColSize(totalItems) {
      const maxItemsPerRow = 5;
      return Math.min(12 / maxItemsPerRow, 12 / totalItems);
    },
    getLifetimeValue() {
      let time = 0;
      let expire_time = new Date(this.dsp_campaign.campaign.expire_time);
      let activate_time = this.dsp_campaign.campaign.activate_time;
      let today = this.formatDate(new Date());
      if (new Date() > new Date(expire_time)) {
        time = this.calculateDateDifference(expire_time, activate_time);
      } else {
        time = this.calculateDateDifference(today, activate_time);
      }
      return time;
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    calculateDateDifference(date1Str, date2Str) {
      const date1 = new Date(date1Str);
      const date2 = new Date(date2Str);

      if (isNaN(date1) || isNaN(date2)) {
        console.error("Invalid date format.");
        return NaN;
      }

      const differenceInMilliseconds = Math.abs(date1 - date2);
      const millisecondsPerDay = 1000 * 60 * 60 * 24;
      const differenceInDays = Math.floor(
        differenceInMilliseconds / millisecondsPerDay
      );
      return differenceInDays;
    },
    metricPercentageChange(metric, value) {
      let percentage = 0;
      if (metric === "Total Spend") {
        let spend = parseInt(value.replace(/[^\d.]/g, ""));
        percentage = (spend / this.budget) * 100;
        percentage =
          isNaN(percentage) ||
            percentage === null ||
            percentage === undefined ||
            !isFinite(percentage)
            ? 0
            : percentage;
      } else if (metric === "Avg. CPC") {
        let CPC = parseFloat(value);
        percentage = (CPC / this.setCPC) * 100;
        percentage =
          isNaN(percentage) ||
            percentage === null ||
            percentage === undefined ||
            !isFinite(percentage)
            ? 0
            : percentage;
      } else if (metric === "Total Impressions") {
        let impressions = parseInt(value);
        percentage = (impressions / this.setImpressions) * 100;
        percentage =
          isNaN(percentage) ||
            percentage === null ||
            percentage === undefined ||
            !isFinite(percentage)
            ? 0
            : percentage;
      } else if (metric === "Total Clicks") {
        let clicks = value;
        percentage = (parseInt(clicks) / this.setClicks) * 100;
        percentage =
          isNaN(percentage) ||
            percentage === null ||
            percentage === undefined ||
            !isFinite(percentage)
            ? 0
            : percentage;
      } else if (metric === "Avg. CPM") {
        let CPM = parseFloat(value);
        percentage = (CPM / this.setCPM) * 100;
        percentage =
          isNaN(percentage) ||
            percentage === null ||
            percentage === undefined ||
            !isFinite(percentage)
            ? 0
            : percentage;
      } else if (metric === "Avg. CTR") {
        let CTR = parseFloat(value);
        percentage = (CTR / this.setCTR) * 100;
        percentage =
          isNaN(percentage) ||
            percentage === null ||
            percentage === undefined ||
            !isFinite(percentage)
            ? 0
            : percentage;
      }
      // else if (metric === "Total Engagements") {

      // } else if (metric === "Avg. CPE") {

      // } else if (metric === "Avg. ER") {

      // } else if (metric === "Total Leads") {

      // } else if (metric === "Avg. CPL") {

      // } else if (metric === "Reach") {

      // } else if (metric === "Total Views") {

      // } else if (metric === "Avg. CPV") {

      // } else if (metric === "Avg. VTR") {

      // }
      return percentage.toFixed(2);
    },
    cardsColorAndSign(metric, value) {
      let difference = 0;
      if (metric === "Total Spend") {
        let spend = parseInt(value.replace(/[^\d.]/g, ""));
        difference = this.budget - spend;
        difference =
          isNaN(difference) || difference === null || difference === undefined
            ? 0
            : difference;
      } else if (metric === "Avg. CPC") {
        let CPC = parseFloat(value);
        difference = this.setCPC - CPC;
        difference =
          isNaN(difference) || difference === null || difference === undefined
            ? 0
            : difference;
      } else if (metric === "Total Impressions") {
        let impressions = parseInt(value);
        difference = this.setImpressions - impressions;
        difference =
          isNaN(difference) || difference === null || difference === undefined
            ? 0
            : difference;
      } else if (metric === "Total Clicks") {
        let clicks = value;
        difference = this.setClicks - parseInt(clicks);
        difference =
          isNaN(difference) || difference === null || difference === undefined
            ? 0
            : difference;
      } else if (metric === "Avg. CPM") {
        let CPM = parseFloat(value);
        difference = this.setCPM - CPM;
        difference =
          isNaN(difference) || difference === null || difference === undefined
            ? 0
            : difference;
      } else if (metric === "Avg. CTR") {
        let CTR = parseFloat(value);
        difference = this.setCTR - CTR;
        difference =
          isNaN(difference) || difference === null || difference === undefined
            ? 0
            : difference;
      }
      if (difference > 0) {
        return {
          color: "green lighten-5",
          textColor: "success",
          sign: "+",
          icon: "mdi-trending-up",
        };
      } else if (difference < 0) {
        return {
          color: "#fee2e1",
          sign: "-",
          textColor: "error",
          icon: "mdi-trending-down",
        };
      } else {
        return {
          color: "blue-grey-lighten-4",
          textColor: "grey",
          sign: "",
          icon: "mdi-trending-neutral",
        };
      }
    },

    /*old code for uploading csv */

    // async sendUploadData(upload_data) {
    //   // console.log(upload_data)
    //   try {
    //     this.$store.commit("makingApiRequest", true);
    //     let upload_body = {
    //       body: upload_data,
    //       campaign_id: this.$route.params.id,
    //     };
    //     await this.$store.dispatch("dsp/uploadLeadGen", upload_body);
    //     this.$store.commit("makingApiRequest", false);
    //     this.$notify({
    //       title: "File Uploaded Successfully!",
    //       group: "success",
    //     });
    //   } catch (error) {
    //     this.error = error.message;
    //     this.$store.commit("makingApiRequest", false);
    //   }
    // },
  },

  async mounted() {
    //make the calls at the same time
    await Promise.all([
      this.getCampaignNotes(),
      this.getCampaignScreenshots(),
      this.getStaticData(),
      this.fetchCampaignReachData(),
    ]);

    this.map = new VectorMap({
      selector: "#basemap_id",
      map: "world",
      selectedRegions: this.map_countries,
      panOnDrag: false,
      zoomOnScroll: false,
      regionStyle: {
        selected: { fill: "orange" },
      },
      zoomButtons: false,
    });

    setTimeout(() => {
      this.apexLoading = true;
    });
  },
  computed: {
    campaign_reach() {

      let data =  this.$store.state.dsp.campaign_reach;

      if(Array.isArray(data) && data.length > 0){
          let reach = data?.map((item) => {
            return item?.count?.value;
          });

          let seriesData = [
            {
              name: "Reach",
              data: reach,
            }
          ];  

          //get sum of total reach
          let totalReach = reach?.reduce((a, b) => a + b, 0);

          //add commas to total reach
          totalReach = totalReach.toLocaleString();

          return {
            seriesData: seriesData,
            totalReach: totalReach,
          };
      }
      else {
        return {
          seriesData: [],
          totalReach: 0,
        };
      }

      
    },
    iab_categories() {
      return this.$store.state.dsp.static_dsp_data.iab_categories
        .iab_categories;
    },
    countries() {
      return this.$store.state.dsp.static_dsp_data.countries_v2;
    },
    _campaign_screenshots() {
      return this.$store.state.dsp.campaign_screenshots;
    },
    _campaignEndDate() {
      return new Date(
        this.campaign_statistics.campaign.expire_time
      ).toLocaleDateString("en-ca");
    },
    _user() {
      return this.$store.state.authenticated_user;
    },
    _notes() {
      return this.$store.state.dsp.campaign_notes;
    },
    _spend() {
      let budget = this.campaign_statistics.campaign.total_budget
        ? this.campaign_statistics.campaign.total_budget
        : 0;
      let spend = this.campaign_statistics.campaign_spend
        ? this.campaign_statistics.campaign_spend
        : 0;
      budget = Number(budget.replace(/,/g, ""));
      if (!this.superAdmin && spend > budget) {
        spend = budget;
      }
      return spend.toFixed(2);
    },

    _campaignProgress() {
      let amountSpent = this._spend;
      let budget = this.campaign_statistics.campaign.total_budget
        ? this.campaign_statistics.campaign.total_budget
        : 0;

      let progress = (amountSpent / budget) * 100;

      if (progress > 100) {
        progress = 100;
      }

      progress = progress.toFixed(0);

      let color = null;

      if (progress < 20) {
        color = "#C8E6C9";
      } else if (progress < 50) {
        color = "#A5D6A7";
      } else if (progress < 80) {
        color = "#81C784";
      } else {
        color = "#1B5E20";
      }

      let data = {
        progress: progress,
        color: color,
      };

      return data;
    },

    balanceData() {
      let budget = Number(
        this.campaign_statistics.campaign.total_budget.replace(/,/g, "")
      );
      let amountSpent = Number(this.spend);
      let remainingBalance = budget - amountSpent;
      return [
        { category: "Amount Spent", amount: amountSpent },
        { category: "Remaining Balance", amount: remainingBalance },
      ];
    },
    seriesData() {
      return this.balanceData.map((item) => item.amount);
    },
    donut_labels() {
      return this.balanceData.map((item) => item.category);
    },
    _impressions() {
      let value = this.campaign_statistics.total_impressions;
      if (
        !this.superAdmin &&
        this.campaign_statistics.total_impressions > this.target_impressions
      ) {
        value = this.target_impressions;
      }
      if (!isFinite(value) || isNaN(value)) {
        value = 0;
      }
      return value;
    },

    _campaignCPL() {
      let value =
        this.campaign_statistics.campaign_spend /
        this.campaign_statistics.campaign.leads_count;
      let budget = this.campaign_statistics.campaign.total_budget
        ? this.campaign_statistics.campaign.total_budget
        : 0;
      budget = Number(budget.replace(/,/g, ""));
      if (
        !this.superAdmin &&
        this.campaign_statistics.campaign_spend > budget
      ) {
        value = budget / this.campaign_statistics.campaign.leads_count;
      }
      if (!isFinite(value) || isNaN(value)) {
        value = 0;
      }
      return value.toFixed(2);
    },
    _campaignCPC() {
      let value = this.campaign_statistics.cpc;
      let budget = this.campaign_statistics.campaign.total_budget
        ? this.campaign_statistics.campaign.total_budget
        : 0;
      budget = Number(budget.replace(/,/g, ""));
      if (
        !this.superAdmin &&
        this.campaign_statistics.campaign_spend > budget
      ) {
        value = budget / this.campaign_statistics.total_clicks;
      }
      if (!isFinite(value) || isNaN(value)) {
        value = 0;
      }
      return value.toFixed(2);
    },
    _campaignMetrics() {
      let leadsSum = this.campaign_statistics.campaign.leads_count
        ? this.campaign_statistics.campaign.leads_count.toLocaleString()
        : 0;

      return [
        {
          text: "Total Impressions",
          value: this._impressions
            ? this._impressions.toLocaleString()
            : this._impressions,
          visibility: true,
          seriesData: this.impressionsSeries,
        },
        {
          text: "Total Clicks",
          value: this.campaign_statistics.total_clicks
            ? this.campaign_statistics.total_clicks.toLocaleString()
            : this.campaign_statistics.total_clicks,
          visibility: true,
          seriesData: this.clicksSeries,
        },
        {
          text: "Total Spend",
          value: parseFloat(this._spend).toLocaleString("en-us", {
            style: "currency",
            currency: "USD",
          }),
          visibility: true,
          seriesData: this.spendSeries,
        },
        {
          text: "Avg. CPM",
          value: parseFloat(
            this.campaign_statistics?.campaign?.campaign_attribute
              ?.campaign_ecpm
          )
            .toFixed(2)
            .toLocaleString("en-us", {
              style: "currency",
              currency: "USD",
            }),
          visibility: true,
          seriesData: this.cpmSeries,
        },

        {
          text: "Avg. CTR",
          value: this.campaign_statistics.ctr,
          visibility: true,
          seriesData: this.ctrSeries,
        },
        {
          text: "Avg. CPC",
          value: this._campaignCPC,
          visibility: true,
          seriesData: this.cpcSeries,
        },
        {
          text: "Total Leads",
          value: this.totalLeads
            ? this.totalLeads.toLocaleString()
            : leadsSum
              ? leadsSum
              : "0",
          visibility: true,
          seriesData: this.leadsSeries,
        },
        {
          text: "Avg. CPL",
          value: `$${this._campaignCPL}`,
          seriesData: this.cplSeries,
        },
        {
          text: "Total Views",
          value: this.campaign_statistics.views
            ? this.campaign_statistics.views.toLocaleString()
            : 0,
          visibility: true,
        },
        {
          text: "Avg. CPV",
          value:
            this.campaign_statistics.views !== 0
              ? (this._spend / this.campaign_statistics.views).toFixed(4)
              : 0,
          visibility: true,
        },
        {
          text: "Avg. VTR",
          value: this.campaign_statistics.vtr
            ? this.campaign_statistics.vtr
            : 0,
          visibility: true,
        },
        {
          text: "Total Engagements",
          value: this.campaign_statistics?.total_engagements?.toLocaleString(),
          seriesData: this.engagementSeries,
          // value: this.campaign_statistics.engagements
          //   ? this.campaign_statistics.engagements.toLocaleString()
          //   : this.campaign_statistics.engagements,
        },
        {
          text: "Avg. CPE",
          value:
            !isNaN(this._spend / this.campaign_statistics.total_engagements) &&
              isFinite(this._spend / this.campaign_statistics.total_engagements)
              ? (
                this._spend / this.campaign_statistics.total_engagements
              ).toFixed(2)
              : 0,
          seriesData: this.cpeSeries,
        },
        {
          text: "Avg. ER",
          value: `${this.campaign_statistics.engagement_rate}%`,
          seriesData: this.erSeries,
        },
        {
          text: "Reach",
          value: this.campaign_statistics.reach
            ? this.campaign_statistics.reach.toLocaleString()
            : 0,
        },
        {
          'text': 'Total Installs',
          visibility: true,
          'value': this.campaign_statistics?.app_installs,
        },
        {
          'text': 'Avg. CPI',
          visibility: true,
          'value': `$${ this.campaign_statistics?.cpi}`,
        }
      ];
    },

    _filteredCampaignMetrics() {
      const metrics = this._campaignMetrics;

      if (
        Array.isArray(this.filterDateRange) &&
        this.filterDateRange.length > 0
      ) {
        const array2Map = new Map(
          this.filterDataBasedOnDate.map((item) => [item.text, item])
        );

        //console.log(this.filterDataBasedOnDate)

        // Update values in metrics based on matching "text" keys in array2
        metrics.forEach((item1, index) => {
          const item2 = array2Map.get(item1.text);
          if (item2) {
            // Update values in metrics with values from array2
            metrics[index].value = item2.value;
          }
        });

        //if key is total spend format to currency
        metrics.forEach((item) => {
          if (item.text === "Total Spend") {
            item.value;
          }
        });
      }

      //Awareness > display, video, engagement
      if (this.campaignObjective === "awareness") {
        if (this.campaignType === "display") {
          //metrics to hide from client
          const hide = ["Avg. CPC"];
          //loop throught the array, update client visibilty key to true
          Object.keys(metrics).map((key) => {
            hide.forEach((item) => {
              if (metrics[key].text === item) {
                metrics[key].visibility = false;
              }
            });
          });
          return metrics.filter((el) => {
            return (
              el.text === "Total Impressions" ||
              el.text === "Total Spend" ||
              el.text === "Total Clicks" ||
              el.text === "Avg. CPM" ||
              // el.text === "Avg. CPC" ||
              el.text === "Avg. CTR"
            );
          });
        } else if (this.campaignType === "video") {
          //metrics to hide from client
          const hide = [];
          //loop throught the array, update client visibilty key to true
          Object.keys(metrics).map((key) => {
            hide.forEach((item) => {
              if (metrics[key].text === item) {
                metrics[key].visibility = false;
              }
            });
          });
          return metrics.filter((el) => {
            return (
              el.text === "Total Impressions" ||
              el.text === "Total Spend" ||
              el.text === "Total Views" ||
              el.text === "Avg. CPV" ||
              el.text === "Avg. VTR"
            );
          });
        } else if (this.campaignType === "engagement") {
          //metrics to hide from client
          const hide = [];
          //loop throught the array, update client visibilty key to true
          Object.keys(metrics).map((key) => {
            hide.forEach((item) => {
              if (metrics[key].text === item) {
                metrics[key].visibility = false;
              }
            });
          });
          return metrics.filter((el) => {
            return (
              el.text === "Total Impressions" ||
              // el.text === "Avg. CPM" ||
              // el.text === "Avg. CTR" ||
              el.text === "Total Spend" ||
              el.text === "Total Engagements" ||
              el.text === "Avg. CPE" ||
              el.text === "Avg. ER"
            );
          });
        }
      } //Traffic > display, video
      else if (this.campaignObjective === "traffic") {
        if (this.campaignType === "display" || this.campaignType === "video") {
          //metrics to hide from client
          const hide = ["Total Impressions"];
          //loop throught the array, update client visibilty key to true
          Object.keys(metrics).map((key) => {
            hide.forEach((item) => {
              if (metrics[key].text === item) {
                metrics[key].visibility = false;
              }
            });
          });
          return metrics.filter((el) => {
            return (
              el.text === "Total Impressions" ||
              el.text === "Total Spend" ||
              el.text === "Total Clicks" ||
              el.text === "Avg. CPC" ||
              el.text === "Avg. CTR"
            );
          });
        }
      } else if (this.campaignObjective === "leads") {
        if (this.campaignType === "display") {
          //metrics to hide from client
          const hide = ["Total Impressions"];
          //loop throught the array, update client visibilty key to true
          Object.keys(metrics).map((key) => {
            hide.forEach((item) => {
              if (metrics[key].text === item) {
                metrics[key].visibility = false;
              }
            });
          });
          return metrics.filter((el) => {
            return (
              el.text === "Total Impressions" ||
              el.text === "Total Spend" ||
              el.text === "Total Leads" ||
              el.text === "Avg. CPL"
            );
          });
        }
      } else if (this.campaignObjective === "reach") {
        if (this.campaignType === "display") {
          //metrics to hide from client
          const hide = ["Total Impressions"];
          //loop throught the array, update client visibilty key to true
          Object.keys(metrics).map((key) => {
            hide.forEach((item) => {
              if (metrics[key].text === item) {
                metrics[key].visibility = false;
              }
            });
          });
          return metrics.filter((el) => {
            return (
              el.text === "Total Impressions" ||
              el.text === "Reach" ||
              el.text === "Total Spend" ||
              el.text === "Total Clicks" ||
              el.text === "Avg. CPM" ||
              el.text === "Avg. CTR"
            );
          });
        }
      } else if(this.campaignObjective == 'installs'){
        //metrics to hide from client
        const hide = [];
          //loop throught the array, update client visibilty key to true
          Object.keys(metrics).map((key) => {
            hide.forEach((item) => {
              if (metrics[key].text === item) {
                metrics[key].visibility = false;
              }
            });
          });
          return metrics.filter((el) => {
            return (
              el.text === "Total Impressions" ||
              el.text === "Total Spend" ||
              el.text === "Total Installs" ||
              el.text === "Avg. CPI"
            );
          });
      } else {
        return metrics.filter((el) => {
          return (
            el.text === "Total Impressions" ||
            el.text === "Total Spend" ||
            el.text === "Total Clicks" ||
            el.text === "Avg. CPM" ||
            el.text === "Avg. CTR"
          );
        });
      }

      return metrics;
    },

    _targets() {
      const target = this.campaign_target;
      if (target !== null) {
        if (target.iab_category) {
          let whitelisted_verticals = [];

          let selectedCategories = target?.iab_category?.split(",");

          for (let i = 0; i < selectedCategories?.length; i++) {
            if (selectedCategories[i].length != 0) {
              let category = this.findById(
                this.iab_categories,
                selectedCategories[i]
              );
              whitelisted_verticals.push(category);
            }
          }

          //return the label only
          whitelisted_verticals = whitelisted_verticals.map(
            (item) => item.label
          );

          //convert to string
          whitelisted_verticals = whitelisted_verticals.toString();

          target["category"] = whitelisted_verticals;
        }

        // only filter when targets are not null
        Object.keys(target).forEach((key) => {
          if (
            target[key] === null ||
            target[key] === undefined ||
            target[key].length === 0 ||
            target[key] === "{}" ||
            key === "os" ||
            key === "customer_id" ||
            key === "sub_id" ||
            key === "agency_id" ||
            key === "activate_time" ||
            key === "expire_time" ||
            key === "created_at" ||
            key === "updated_at" ||
            key === "id" ||
            key === "iab_category"
          ) {
            // remove null pretargets
            delete target[key];
          }
        });
      }

      return target;
    },
    selectCharts() {
      let charts;
      if (this.isLead && this.campaignType === "display") {
        charts = this.chart_items.filter(
          (chart) =>
            chart !== "CTR" &&
            chart !== "Clicks" &&
            chart !== "CPM" &&
            chart !== "CPC" &&
            chart !== "ER" &&
            chart !== "Engagements" &&
            chart !== "CPE" &&
            chart !== "Reach" &&
            chart !== "Frequency" &&
            chart !== "CPA" &&
            chart !== "CPI" &&
            chart !== "Installs" &&
            chart !== "Acquisitions" &&
            chart !== "Views" &&
            chart !== "VTR" &&
            chart !== "CPV"
        );
      } else if (this.isAwareness && this.campaignType === "video") {
        charts = this.chart_items.filter(
          (chart) =>
            chart !== "CPC" &&
            chart !== "Leads" &&
            chart !== "CPM" &&
            chart !== "Clicks" &&
            chart !== "CTR" &&
            chart !== "CPL" &&
            chart !== "ER" &&
            chart !== "Engagements" &&
            chart !== "Reach" &&
            chart !== "CPE" &&
            chart !== "Frequency" &&
            chart !== "CPA" &&
            chart !== "CPI" &&
            chart !== "Installs" &&
            chart !== "Acquisitions"
        );
      } else if (this.isAwareness && this.campaignType === "engagement") {
        charts = this.chart_items.filter(
          (chart) =>
            chart !== "CPC" &&
            chart !== "Leads" &&
            chart !== "CPM" &&
            chart !== "Clicks" &&
            chart !== "CTR" &&
            chart !== "CPL" &&
            chart !== "Reach" &&
            chart !== "Frequency" &&
            chart !== "CPA" &&
            chart !== "CPI" &&
            chart !== "Installs" &&
            chart !== "Acquisitions" &&
            chart !== "CPL" &&
            chart !== "Views" &&
            chart !== "VTR" &&
            chart !== "CPV"
        );
      } else if (this.isAwareness && this.campaignType === "display") {
        charts = this.chart_items.filter(
          (chart) =>
            chart !== "CPC" &&
            chart !== "Leads" &&
            chart !== "CPM" &&
            chart !== "CPL" &&
            chart !== "ER" &&
            chart !== "Engagements" &&
            chart !== "CPE" &&
            chart !== "Reach" &&
            chart !== "Frequency" &&
            chart !== "CPA" &&
            chart !== "CPI" &&
            chart !== "Installs" &&
            chart !== "Acquisitions" &&
            chart !== "CPL" &&
            chart !== "Views" &&
            chart !== "VTR" &&
            chart !== "CPV"
        );
      } else if (this.isTraffic && this.campaignType === "display") {
        charts = this.chart_items.filter(
          (chart) =>
            chart !== "Impressions" &&
            chart !== "CPM" &&
            chart !== "Leads" &&
            chart !== "CPL" &&
            chart !== "ER" &&
            chart !== "Engagements" &&
            chart !== "CPE" &&
            chart !== "Reach" &&
            chart !== "Frequency" &&
            chart !== "CPA" &&
            chart !== "CPI" &&
            chart !== "Installs" &&
            chart !== "Acquisitions" &&
            chart !== "CPL" &&
            chart !== "Views" &&
            chart !== "VTR" &&
            chart !== "CPV"
        );
      } else if (this.isTraffic && this.campaignType === "video") {
        charts = this.chart_items.filter(
          (chart) =>
            chart !== "Impressions" &&
            chart !== "CPM" &&
            chart !== "Leads" &&
            chart !== "CPL" &&
            chart !== "ER" &&
            chart !== "Engagements" &&
            chart !== "CPE" &&
            chart !== "Reach" &&
            chart !== "Frequency" &&
            chart !== "CPA" &&
            chart !== "CPI" &&
            chart !== "Installs" &&
            chart !== "Acquisitions" &&
            chart !== "CPL" &&
            chart !== "CPC" &&
            chart !== "Clicks" &&
            chart !== "CTR"
        );
      } else if (this.isReach && this.campaignType === "display") {
        charts = this.chart_items.filter(
          (chart) =>
            chart !== "CPM" &&
            chart !== "Leads" &&
            chart !== "CPL" &&
            chart !== "ER" &&
            chart !== "Engagements" &&
            chart !== "CPE" &&
            chart !== "CPA" &&
            chart !== "CPI" &&
            chart !== "Installs" &&
            chart !== "Acquisitions" &&
            chart !== "CPL" &&
            chart !== "CPC" &&
            chart !== "Clicks" &&
            chart !== "CTR" &&
            chart !== "Views" &&
            chart !== "VTR" &&
            chart !== "CPV"
        );
      } else if (this.isReach && this.campaignType === "video") {
        charts = this.chart_items.filter(
          (chart) =>
            chart !== "CPM" &&
            chart !== "Leads" &&
            chart !== "CPL" &&
            chart !== "ER" &&
            chart !== "Engagements" &&
            chart !== "CPE" &&
            chart !== "CPA" &&
            chart !== "CPI" &&
            chart !== "Installs" &&
            chart !== "Acquisitions" &&
            chart !== "CPL" &&
            chart !== "CPC" &&
            chart !== "Clicks" &&
            chart !== "CTR"
        );
      } else if (this.isReach && this.campaignType === "engagement") {
        charts = this.chart_items.filter(
          (chart) =>
            chart !== "CPC" &&
            chart !== "Leads" &&
            chart !== "CPM" &&
            chart !== "Clicks" &&
            chart !== "CTR" &&
            chart !== "CPL" &&
            chart !== "CPA" &&
            chart !== "CPI" &&
            chart !== "Installs" &&
            chart !== "Acquisitions" &&
            chart !== "CPL" &&
            chart !== "Views" &&
            chart !== "VTR" &&
            chart !== "CPV"
        );
      } else if (this.isAppInstalls && this.campaignType === "display") {
        charts = this.chart_items.filter(
          (chart) =>
            chart !== "Impressions" &&
            chart !== "CPM" &&
            chart !== "Leads" &&
            chart !== "CPL" &&
            chart !== "ER" &&
            chart !== "Engagements" &&
            chart !== "CPE" &&
            chart !== "CPA" &&
            chart !== "Acquisitions" &&
            chart !== "CPL" &&
            chart !== "CPC" &&
            chart !== "Clicks" &&
            chart !== "CTR" &&
            chart !== "Views" &&
            chart !== "VTR" &&
            chart !== "CPV"
        );
      } else if (this.isConversion && this.campaignType === "display") {
        charts = this.chart_items.filter(
          (chart) =>
            chart !== "Impressions" &&
            chart !== "CPM" &&
            chart !== "Leads" &&
            chart !== "CPL" &&
            chart !== "ER" &&
            chart !== "Engagements" &&
            chart !== "CPE" &&
            chart !== "CPI" &&
            chart !== "CPL" &&
            chart !== "CPC" &&
            chart !== "Clicks" &&
            chart !== "CTR" &&
            chart !== "Views" &&
            chart !== "VTR" &&
            chart !== "CPV"
        );
      } else {
        charts = this.chart_items.filter(
          (chart) =>
            chart !== "Leads" &&
            chart !== "CPL" &&
            chart !== "ER" &&
            chart !== "Engagements" &&
            chart !== "CPE" &&
            chart !== "CPI" &&
            chart !== "CPL" &&
            chart !== "CPC" &&
            chart !== "Clicks" &&
            chart !== "CTR" &&
            chart !== "Views" &&
            chart !== "VTR" &&
            chart !== "CPV" &&
            chart !== "Reach" &&
            chart !== "Frequency" &&
            chart !== "CPA" &&
            chart !== "Installs" &&
            chart !== "Acquisitions"
        );
      }
      return charts;
    },
    campaignType() {
      return this.campaign_statistics?.campaign?.campaign_attribute
        ?.campaign_type;
    },
    campaignObjective() {
      return this.dsp_campaign?.campaign?.campaign_attribute
        ?.campaign_objective;
    },
    isAwareness() {
      return (
        this.dsp_campaign?.campaign?.campaign_attribute?.campaign_objective ===
        "awareness"
      );
    },
    isLead() {
      return (
        this.dsp_campaign?.campaign?.campaign_attribute?.campaign_objective ===
        "leads"
      );
    },
    isTraffic() {
      return (
        this.dsp_campaign?.campaign?.campaign_attribute?.campaign_objective ===
        "traffic"
      );
    },
    isEngagement() {
      return (
        this.dsp_campaign?.campaign?.campaign_attribute?.campaign_type ===
        "engagement"
      );
    },
    isReach() {
      return (
        this.dsp_campaign?.campaign?.campaign_attribute?.campaign_objective ===
        "reach"
      );
    },
    isAppInstalls() {
      return (
        this.dsp_campaign?.campaign?.campaign_attribute?.campaign_objective ===
        "app_installs"
      );
    },
    isConversion() {
      return (
        this.dsp_campaign?.campaign?.campaign_attribute?.campaign_objective ===
        "conversion"
      );
    },
    logs() {
      return this.dsp_campaign.activity;
    },
    bannerStats() {
      return this.campaign_statistics.banner_performance;
    },
    combined() {
      const bannerPerfomance = this.bannerStats;
      const campaignBanners = this.dsp_campaign.campaign.campaign_banners;
      const videoBanners = this.dsp_campaign?.campaign?.video_banners; 
      const nativeBanners = this.dsp_campaign?.campaign?.native_banners;


      if(Array.isArray(videoBanners) && videoBanners.length > 0){
        campaignBanners.push(...videoBanners);
      }

      if(Array.isArray(nativeBanners) && nativeBanners.length > 0){
        campaignBanners.push(...nativeBanners);
      }

      // console.log(array2);
      const combinedArray = campaignBanners.map((item1) => {
        const item2 = bannerPerfomance.find(
          (item2) => Number(item2.banner_id) === item1.id
        );

        return {
          id: item1.id,
          name: item1.name,
          type: item1.type,
          width: item1.width ?? "-",
          height: item1.height ?? "-",
          imageurl: item1.imageurl,
          created_at: item1.created_at,
          url: item1.imageurl,
          impressions: item2 ? item2.impressions : 0,
          ctr: item2 ? item2.ctr : (0).toFixed(2),
          clicks: item2 ? item2.clicks : 0,
        };
      });

      return combinedArray;
    },

    __bannerImpressionsGraphData() {
      const bannerPerfomance = this.bannerStats;
      const campaignBanners = this.dsp_campaign.campaign.campaign_banners;

      const combinedArray = campaignBanners.map((item1) => {
        const item2 = bannerPerfomance.find(
          (item2) => Number(item2.banner_id) === item1.id
        );
        return {
          size: `${item1.width}x${item1.height}`,
          data: item2 ? item2.impressions : 0,
        };
      });

      const outputData = {};

      // Iterate through the input array
      combinedArray.forEach((item) => {
        const { size, data } = item;

        // If the size is already in the outputData, add the data
        if (outputData[size]) {
          outputData[size].data = (
            parseFloat(outputData[size].data) + parseFloat(data)
          ).toFixed(2);
        } else {
          // Otherwise, create a new entry in outputData
          outputData[size] = { size, data };
        }
      });

      // Convert the outputData object back to an array
      const resultArray = Object.values(outputData);

      return resultArray;
    },

    __bannerCTRGraphData() {
      const bannerPerfomance = this.bannerStats;
      const campaignBanners = this.dsp_campaign.campaign.campaign_banners;

      const combinedArray = campaignBanners.map((item1) => {
        const item2 = bannerPerfomance.find(
          (item2) => Number(item2.banner_id) === item1.id
        );
        return {
          size: `${item1.width}x${item1.height}`,
          data: item2 ? item2.ctr : (0).toFixed(2),
        };
      });

      const outputData = {};

      combinedArray.forEach((item) => {
        const { size, data } = item;

        if (outputData[size]) {
          outputData[size].data = (
            (parseFloat(outputData[size].data) + parseFloat(data)) /
            2
          ).toFixed(2);
        } else {
          outputData[size] = { size, data };
        }
      });

      const resultArray = Object.values(outputData);

      return resultArray;
    },

    __bannerPreviewStats() {
      //find banner from combined array where id is equal to id
      let id = this.sample_banner?.id;

      const banner = this.combined.find((item) => item.id === id);

      return banner;
    },

    campaignStatus() {
      return this.dsp_campaign.activity_status;
    },
    sample_banner() {
      return this.dsp_campaign.campaign.campaign_banners.find(
        ({ width, height }) => width === 300 && height === 250
      );
    },
    dsp_campaign() {
      return this.$store.state.dsp.dsp_campaign;
    },
    dsp_targets() {
      return this.$store.state.dsp.dsp_targets;
    },
    duration() {
      let duration =
        new Date(this.campaign_statistics.campaign.expire_time).getTime() -
        new Date(this.campaign_statistics.campaign.activate_time).getTime();
      return (duration / (1000 * 3600 * 24)).toFixed();
    },
    dateRange() {
      return this.dates.join(" -- ");
    },
    superAdmin() {
      return this.$store.getters.superAdmin;
    },
    campaign_statistics() {
      return this.$store.state.dsp.campaign_statistics;
    },
    target_impressions() {
      let budget = this.campaign_statistics.campaign.total_budget
        ? this.campaign_statistics.campaign.total_budget
        : 0;

      let ecpm = this.campaign_statistics?.campaign?.campaign_attribute
        ?.campaign_ecpm
        ? this.campaign_statistics?.campaign?.campaign_attribute?.campaign_ecpm
        : 0;

      let impressions = (budget / ecpm) * 1000;

      //make sure impressions is a number
      if (isNaN(impressions)) {
        impressions = 0;
      }

      //handle infinity
      if (impressions === Infinity) {
        impressions = 0;
      }
      return parseInt(impressions);
    },
    bannerHeadersNew() {
      let headers = this.bannerHeaders;
      if (this.isLead || this.isEngagement) {
        headers = [
          { text: "ID", align: "start", value: "id" },
          { text: "Banner", align: "start", value: "name" },
          { text: "Size", align: "start", value: "width" },
          { text: "Type", align: "start", value: "type" },
          { text: "Impressions", align: "start", value: "impressions" },
        ];
      }
      return headers;
    },
    filteredMetrics() {
      return this._filteredCampaignMetrics.filter(
        (item) => this.superAdmin || item.visibility
      );
    },
    sortedFilteredMetrics() {
      const specificTexts = [
        "Total Impressions",
        "Total Clicks",
        "Avg. CTR",
        "Total Spend",
      ];
      const specificItems = [];
      const remainingItems = [];

      for (const item of this.filteredMetrics) {
        if (specificTexts.includes(item.text)) {
          if (item.text === "Total Impressions") {
            specificItems.unshift(item);
          } else {
            specificItems.push(item);
          }
        } else {
          remainingItems.push(item);
        }
      }

      return [...specificItems, ...remainingItems];
    },
  },
  watch: {
    "$route.params.id": {
      handler: function (value) {
        this.singleCampaignStats(value);
        this.getCampaignData();
      },
      deep: true,
      immediate: true,
    },
    // selectCharts: {
    //   deep: true,
    // },

    dsp_campaign() {
      this.setClicks =
        this.dsp_campaign.campaign.campaign_attribute.clicks != null
          ? parseFloat(this.dsp_campaign.campaign.campaign_attribute.clicks)
          : parseFloat(this.dsp_campaign.campaign.total_budget) /
          parseFloat(
            this.dsp_campaign.campaign.campaign_attribute.campaign_ecpc
          );
      this.setImpressions = parseInt(
        this.dsp_campaign.campaign.campaign_attribute.impressions
      );
      this.setCPC = parseFloat(
        this.dsp_campaign.campaign.campaign_attribute.campaign_ecpc
      );
      this.budget = parseInt(this.dsp_campaign.campaign.total_budget);
      this.setCPM = parseFloat(
        this.dsp_campaign.campaign.campaign_attribute.campaign_ecpm
      );
      this.setCTR = (this.setClicks / this.setImpressions) * 100;
      this.populateCountries();

      this.campaign_target = this.dsp_campaign.campaign.target;
    },

    campaign_statistics() {
      this.banners = this.campaign_statistics.banner_performance;
      this.stats = this.campaign_statistics.campaign_graphs;
      this.getStartDates(this.campaign_statistics.campaign.activate_time);
      this.getEndDates(this.campaign_statistics.campaign.expire_time);
      this.initialStart();
      this.downloadLeads();
      this.date_Leads = this.campaign_statistics.sum_date_leads
        ? this.campaign_statistics.sum_date_leads
        : [];
      //Card charts
      this.impressionsSeries = {
        name: "Impressions",
        data: this.stats.map((item) => item.total_impressions),
      };

      this.clicksSeries = {
        name: "Clicks",
        data: this.stats.map((item) => item.total_clicks),
      };

      this.spendSeries = {
        name: "Spend",
        data: this.stats.map((item) => parseFloat(item.total_spend)),
      };

      this.cpmSeries = {
        name: "CPM",
        data: this.stats.map((item) => parseFloat(item.average_cpm)),
      };

      this.ctrSeries = {
        name: "CTR",
        data: this.stats.map((item) => parseFloat(item.average_ctr)),
      };

      this.cpcSeries = {
        name: "CPC",
        data: this.stats.map((item) =>
          item.average_cpc
            ? parseFloat(item.average_cpc)
            : item.total_clicks !== 0 && item.total_spend
              ? parseFloat(item.total_spend) / parseFloat(item.total_clicks)
              : null
        ),
      };

      this.leadsSeries = {
        name: "Leads",
        data: this.campaign_statistics.sum_date_leads
          ? Object.values(this.campaign_statistics.sum_date_leads)
          : [],
      };

      this.cplSeries = {
        name: "CPL",
        data: this.stats.map((item) => {
          if (item.total_clicks !== 0) {
            return parseFloat(item.total_spend) / parseFloat(item.total_clicks);
          } else {
            return 0;
          }
        }),
      };

      let statsDates = this.stats.map((entry) => entry.date);
      this.engagementSeries = {
        name: "Engagements",
        data: statsDates.map((date) => {
          const engagementsData = this.campaign_statistics.engagements
            ? this.campaign_statistics.engagements.events_by_date[date]
            : [];
          return engagementsData ? engagementsData.length : 0;
        }),
      };

      this.cpeSeries = {
        name: "CPE",
        data: this.campaign_statistics.campaign_graphs.map((graph) => {
          const totalSpend = parseFloat(graph.total_spend);
          const dateEngagements = this.campaign_statistics.engagements
            ? this.campaign_statistics.engagements.events_by_date[graph.date]
            : [];
          const sumOfEngagements = dateEngagements ? dateEngagements.length : 0;
          return sumOfEngagements > 0 ? totalSpend / sumOfEngagements : 0;
        }),
      };

      this.erSeries = {
        name: "Avg. ER",
        data: this.campaign_statistics.campaign_graphs.map((graph) => {
          const totalImpressions = parseFloat(graph.total_impressions);
          const dateEngagements = this.campaign_statistics.engagements
            ? this.campaign_statistics.engagements.events_by_date[graph.date]
            : [];
          const sumOfEngagements = dateEngagements ? dateEngagements.length : 0;
          return sumOfEngagements > 0
            ? (sumOfEngagements / totalImpressions) * 100
            : 0;
        }),
      };

      this.card_options = {
        ...this.card_options,
        xaxis: {
          type: "datetime",
          categories: this.stats.map((item) => item.date),
          labels: {
            format: "MMM dd",
          },
        },
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.lists {
  list-style: none;

  li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  li:last-child {
    border-bottom: none;
  }
}

#basemap_id {
  height: 400px;
}

#container {
  width: 100%;
  display: inline-block;
  overflow: hidden;
}

.card-title {
  flex-wrap: nowrap !important;
  display: block !important;
}

tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>