<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" :width="width">
      <v-container fluid>
        <v-card>
          <v-toolbar dark color="primary" flat class="pl-0">
            <v-toolbar-title class="text-h6 white--text">{{
              banner.name
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon @click="dismiss">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-text>
            <v-row>
              <v-col cols>
                <div>
                  <iframe
                    :src="banner.imageurl"
                    frameborder="0"
                    :height="banner.height"
                    :width="banner.width"
                    class="grey lighten-4"
                  ></iframe>
                </div>
              </v-col>
              <v-col cols>
                <v-list subheader flat>
                  <v-subheader>Banner Information</v-subheader>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        >{{ banner.width }} x
                        {{ banner.height }}</v-list-item-title
                      >
                      <v-list-item-subtitle>Size</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="banner.created_at">
                    <v-list-item-content>
                      <v-list-item-title>{{
                        new Date(banner.created_at).toLocaleDateString()
                      }}</v-list-item-title>
                      <v-list-item-subtitle>Created</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="banner.banner_click_effect">
                    <v-list-item-content>
                      <v-list-item-title
                        ><span class="text-capitalize">{{
                          banner.banner_click_effect
                        }}</span></v-list-item-title
                      >
                      <v-list-item-subtitle
                        >Banner Click Effect</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="banner.banner_objective">
                    <v-list-item-content>
                      <v-list-item-title
                        ><span class="text-capitalize">{{
                          banner.banner_objective
                            ? banner.banner_objective
                            : "-"
                        }}</span></v-list-item-title
                      >
                      <v-list-item-subtitle
                        >Campaign Objective</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="banner.banner_type">
                    <v-list-item-content>
                      <v-list-item-title
                        ><span class="text-capitalize">{{
                          banner.banner_type ? banner.banner_type : "-"
                        }}</span></v-list-item-title
                      >
                      <v-list-item-subtitle>Campaign Type</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="banner.status">
                    <v-list-item-content>
                      <v-list-item-title
                        ><span class="text-capitalize">{{
                          banner.status ? "Submitted" : "Not Submitted"
                        }}</span></v-list-item-title
                      >
                      <v-list-item-subtitle
                        >Submission Status</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="banner.submission_status">
                    <v-list-item-content>
                      <v-list-item-title
                        ><span class="text-capitalize">{{
                          banner.submission_status ? "Approved" : "Pending"
                        }}</span></v-list-item-title
                      >
                      <v-list-item-subtitle
                        >Approval Status</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="pa-3">
            <v-spacer></v-spacer>
            <v-btn text @click="dismiss"> Cancel </v-btn>
          </v-card-actions>
        </v-card></v-container
      >
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    banner: {
      type: Object,
    },
    width: {
      value: [Number, String],
    },
  },
  methods: {
    dismiss() {
      this.$emit("dismiss");
    },
  },
};
</script>
<style scoped>
.v-list-item__content {
  padding: 4px 0;
}

</style>
