<template>
  <div>
    <v-card height="420" flat>
      <v-card-title class="py-2 d-flex justify-space-between">
        <h6>Top Publishers</h6>

        <div>
          <v-btn
            small
            color="blue-grey"
            @click.stop="isShowDetailsTopAppsDialogOpen = true"
            class="white--text"
            depressed
          >
            View More
          </v-btn>
        </div>
      </v-card-title>

      <v-divider></v-divider>

      <v-simple-table v-if="top10apps.length" dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Publisher</th>
              <th class="text-left">Reach</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(item, index) in top10apps" :key="index">
              <td>{{ item.name }}</td>
              <td>
                <div class="d-flex align-center">
                  <v-progress-linear
                    class="mr-2"
                    rounded
                    :value="item.percentage"
                    height="7"
                    color="#6092c0"
                  ></v-progress-linear>

                  <h6>{{ item.percentage }}</h6>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <div v-else>
        <v-card height="375" flat class="d-flex align-center justify-center">
          <div class="text-center py-10">
            <v-icon x-large>mdi-alert-circle-outline</v-icon>
            <p class="text-caption text-disabled">No data available</p>
          </div>
        </v-card>
      </div>
    </v-card>

    <!-- Dialog -->
    <v-dialog v-model="isShowDetailsTopAppsDialogOpen" max-width="600" max-height="500" scrollable>
      <v-card flat>
        <v-card-title class="py-2 d-flex justify-space-between">
          <h6>Top 100 Publishers</h6>
        </v-card-title>

        <v-divider></v-divider>

        <v-simple-table v-if="top_apps.length" dense>

          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Publisher</th>
                <th class="text-left">Reach</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in top_apps" :key="index">
                <td>{{ item.name }}</td>
                <td>
                  <div class="d-flex align-center">
                    <v-progress-linear
                      class="mr-2"
                      rounded
                      :value="item.percentage"
                      height="7"
                      color="#6092c0"
                    ></v-progress-linear>

                    <h6>{{ item.percentage }}</h6>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div v-else>
          <v-card height="375" flat class="d-flex align-center justify-center">
            <div class="text-center py-10">
              <v-icon x-large>mdi-alert-circle-outline</v-icon>
              <p class="text-caption text-disabled">No data available</p>
            </div>
          </v-card>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      isShowDetailsTopAppsDialogOpen: false,
    }
  },
  props: {
    campaign: {
      type: String,
      validator: function (value) {
        return Number(value)
      },
    },
  },
  mounted() {
    this.getTopApps()
  },
  computed: {
    top_apps() {
      let data = this.$store.state.dsp.top_apps

      // Calculate total count
      const totalCount = data.reduce((acc, curr) => acc + curr.doc_count, 0)

      // Calculate percentage and create new array with Name, amount, and percentage
      const result = data.map((item) => ({
        name: item.key,
        amount: item.doc_count.toLocaleString(),
        percentage: ((item.doc_count / totalCount) * 100).toFixed(2) + "%",
      }))

      //return the first 100
        return result
    },

    top10apps() {
      return this.top_apps.slice(0, 10)
    },
  },

  methods: {
    async getTopApps() {
      this.loading = true

      try {
        let body = {
          campaign: this.campaign,
          body: {
            size: 0,
            query: {},
            aggs: {
              test_histogram: {
                terms: {
                  field: "domain.keyword",
                  size: 100,
                },
              },
            },
          },
        }

        await this.$store.dispatch("dsp/getTopApps", body)
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
