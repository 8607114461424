<template>
  <v-card flat height="300">
    <v-card-title class="d-flex align-center py-1">
      <h6>Citywise Distribution</h6>
    </v-card-title>

    <v-divider></v-divider>

    <div v-if="distribution.length">
      <v-card-text>
        <apexchart
          type="treemap"
          height="230"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </v-card-text>
    </div>

    <!-- <v-simple-table v-if="distribution.length || false" dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">City</th>
            <th class="text-left">Reach</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(item, index) in distribution" :key="index">
            <td>{{ item.name }}</td>
            <td>
              <div class="d-flex align-center">
                <v-progress-linear
                  class="mr-2"
                  rounded
                  :value="item.percentage"
                  height="7"
                  color="#6092c0"
                ></v-progress-linear>

                <h6>{{ item.percentage }}</h6>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table> -->

    <div v-else>
      <v-card height="255" flat class="d-flex align-center justify-center">
        <div class="text-center py-10">
          <v-icon x-large>mdi-alert-circle-outline</v-icon>
          <p class="text-caption text-disabled">No data available</p>
        </div>
      </v-card>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      chartOptions: {
        legend: {
          show: false,
        },
        chart: {
          height: 230,
          type: "treemap",
          toolbar: {
            show: false,
          },
        },
        colors: [
          "#3B93A5",
          "#F7B844",
          "#ADD8C7",
          "#EC3C65",
          "#CDD7B6",
          "#C1F666",
          "#D43F97",
          "#1E5D8C",
          "#421243",
          "#7F94B0",
          "#EF6537",
          "#C0ADDB",
        ],
        plotOptions: {
          treemap: {
            distributed: true,
            enableShades: false,
          },
        },
      },
      series: [
        {
          data: []
        },
      ],
    }
  },
  //props
  props: {
    campaign: {
      type: String,
      validator: function (value) {
        return Number(value)
      },
    },
  },
  mounted() {
    this.getCityWiseDistribution()
  },
  computed: {
    distribution() {
      return this.$store.state.dsp.city_wise_distribution
    },
  },

  watch: {
    distribution: {
      handler: function (val) {
        // Calculate total count
        const totalCount = val.reduce((acc, curr) => acc + curr.doc_count, 0)

        // Calculate percentage and create new array with Name, amount, and percentage
        const result = val.slice(0, 7).map((item) => ({
          name: item.key,
          amount: item.doc_count.toLocaleString(),
          percentage: ((item.doc_count / totalCount) * 100).toFixed(2) + "%",
        }))

        const transformedArray = result.map((item) => ({
          x: item.name,
          y: parseInt(item.amount.replace(/,/g, ""), 10),
        }))

        //set that to series
        this.series = [
          {
            data: transformedArray,
          },
        ]
        
      },
      deep: true,
    },
  },

  methods: {
    async getCityWiseDistribution() {
      this.loading = true

      try {
        let body = {
          campaign: this.campaign,
          body: {
            size: 0,
            query: {},
            aggs: {
              test_histogram: {
                terms: {
                  field: "r_device.geo.city.keyword",
                  size: 10,
                },
              },
            },
          },
        }

        await this.$store.dispatch("dsp/getCityWiseDistribution", body)
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
