<template>
    <v-row>
        <v-col v-for="(combination, index) in adjustedCombinations" :key="index">
            <v-card flat>
                <apexchart :ref="'chart' + index" type="line" height="350" :options="{ ...lineChartOptions }"
                    :series="combination" />
            </v-card>
        </v-col>
    </v-row>
</template>
  
<script>
import config from "@/apex-config";
export default {
    props: {
        stats: {
            type: Array,
        },
        campaignObjective: {
            type: String,
        },
        campaignType: {
            type: String,
        },
        campaignEndDate: {
            type: String,
        },
        dateRange: {},
        campaign_statistics: {},
        date_Leads: {},
    },

    data() {
        return {
            config,
            apexLoading: false,
            dates: [],
            menu: false,
            startDate: null,
            endDate: null,
            lineChartOptions: {
                chart: {
                    type: "line",
                    height: 300,
                    zoom: {
                        enabled: true,
                    },
                    toolbar: {
                        show: false,
                    },
                    animation: {
                        enabled: false,
                    },
                },

                noData: {
                    text: "No Data...",
                    align: "center",
                    verticalAlign: "middle",
                },
                colors: ['#1876d2', '#d36085'],
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    width: [2, 2],
                    dashArray: [0, 0],
                    curve: ["smooth", "smooth"],
                },

                markers: {
                    size: 3,
                    style: "hollow",
                    hover: {
                        sizeOffset: 4,
                    },
                },
                xaxis: {
                    type: "category",
                },

                yaxis: [
                    {
                        opposite: false,
                        show: true,
                        showForNullSeries: false,
                        decimalsInFloat: 2,
                    },
                    {
                        opposite: true,
                        show: true,
                        showForNullSeries: false,
                        decimalsInFloat: 2,
                    },
                ],

                legend: {
                    opposite: false,
                    show: true,
                    showForNullSeries: false,
                    position: "top",
                    horizontalAlign: "left",
                    itemMargin: {
                        horizontal: 5,
                        vertical: 5,
                    },
                },
                grid: {
                    borderColor: '#e7e7e7',
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
            },
            series: [],
            combinations: [],
            charts: [],
            dailyCPM: [],
            dailyCTR: [],
            dailyClicks: [],
            dailyImpressions: [],
            chartDates: [],
            dailySpend: [],
            dailyLeads: [],
            dailyCPC: [],
            dailyCPL: [],
            dailyEngagements: [],
            dailyCPE: [],
            dailyER: []
        };
    },
    watch: {
        stats() {
            this.generateChartData();
            this.combinations = this.getCampaignCombinations();
        },
        dateRange() {
            if (this.dateRange.at(-1) < this.dateRange.at(0)) {
                this.dateRange = this.dateRange.reverse();
            }
            for (let i = 0; i < this.combinations.length; i++) {
                const chart = this.$refs['chart' + i][0];
                chart.zoomX(
                    new Date(this.dateRange.at(0)).getTime(),
                    new Date(this.dateRange.at(-1)).getTime()
                );
            }
        }
    },
    computed: {
        dateRangeText() {
            return this.dateRange.join("  -  ");
        },
        adjustedCombinations() {
            return this.combinations.map(innerArray => {
                if (innerArray.length === 1) {
                    return [innerArray[0], innerArray[0]];
                } else {
                    return innerArray;
                }
            });
        }
    },
    mounted() {
        setTimeout(() => {
            this.apexLoading = true;
        });
    },
    methods: {
        generateChartData() {
            const filteredStats = this.stats.filter((stat) => {
                // if (!this.superAdmin) {
                //   return stat.total_impressions > 10000; // hide campaigns with less than 10k impressions from client view
                // }
                return stat.total_impressions > 0;
            });
            this.dailyImpressions = filteredStats.map((value) =>
                Number.parseInt(value.total_impressions)
            );

            this.dailySpend = filteredStats.map((value) =>
                Number.parseFloat(value.total_spend)
            );

            this.dailyClicks = filteredStats.map((value) =>
                Number.parseInt(value.total_clicks)
            );

            this.dailyCTR = filteredStats.map((value) =>
                Number.parseFloat(value.average_ctr)
            );
            this.dailyCPM = filteredStats.map((value) =>
                Number.parseFloat(value.average_cpm)
            );
            this.dailyCPC = filteredStats.map((value) => {
                const totalSpend = parseFloat(value.total_spend);
                const totalClicks = value.total_clicks;
                return totalClicks !== 0 ? totalSpend / totalClicks : 0;
            });
            this.dailyCPV = filteredStats.map((value) =>
                Number.parseFloat(value.views ? value.views / value.total_spend : 0)
            );
            this.dailyViews = filteredStats.map((value) =>
                value.views ? Number.parseFloat(value.views) : 0
            );
            this.dailyVTR = filteredStats.map((value) =>
                value.viewThroughs ? Number.parseFloat(value.viewThroughs / value.total_impressions) * 100 : 0
            );
            this.dailyCPL = filteredStats.map((item) => { if (item.total_clicks !== 0) { return parseFloat(item.total_spend) / parseFloat(item.total_clicks); } else { return 0; } });

            let statsDates = filteredStats.map(entry => entry.date);
            this.dailyEngagements = statsDates.map(date => {
                const engagementsData = this.campaign_statistics.engagements ? this.campaign_statistics.engagements.events_by_date[date] : 0;
                return engagementsData ? engagementsData.length : 0;
            });

            this.dailyCPE = filteredStats.map(stat => {
                const totalSpend = parseFloat(stat.total_spend);
                const dateEngagements = this.campaign_statistics.engagements ? this.campaign_statistics.engagements.events_by_date[stat.date] : 0;
                const sumOfEngagements = dateEngagements ? dateEngagements.length : 0;
                return sumOfEngagements > 0 ? totalSpend / sumOfEngagements : 0;
            });

            this.dailyER = filteredStats.map(stat => {
                const totalImpressions = parseFloat(stat.total_impressions);
                const dateEngagements = this.campaign_statistics.engagements ? this.campaign_statistics.engagements.events_by_date[stat.date] : 0;
                const sumOfEngagements = dateEngagements ? dateEngagements.length : 0;
                return sumOfEngagements > 0 ? (sumOfEngagements / totalImpressions) * 100 : 0;
            });
            //dailyER
            //Engagements
            //dailyReach
            //dailyCPL
            //dailyCPI
            //dailyCPA

            this.chartDates = filteredStats.map((value) => value.date);

            this.startDate = this.chartDates.at(0);
            this.endDate = this.chartDates.at(-1);

            this.dates = [this.startDate, this.endDate];

            this.series = [
                {
                    name: "Impressions",
                    data: this.dailyImpressions ? this.dailyImpressions : [],
                },
                {
                    name: "Clicks",
                    data: this.dailyClicks ? this.dailyClicks : [],
                },
                {
                    name: "CTR",
                    data: this.dailyCTR ? this.dailyCTR : [],
                },
                {
                    name: "CPM",
                    data: this.dailyCPM ? this.dailyCPM : [],
                },
                {
                    name: "Leads",
                    data: this.date_Leads ? Object.values(this.date_Leads) : []
                },
                {
                    name: "Spend",
                    data: this.dailySpend ? this.dailySpend : [],
                },
                {
                    name: "CPC",
                    data: this.dailyCPC ? this.dailyCPC : [],
                },
                {
                    name: "Views",
                    data: this.dailyViews ? this.dailyViews : [],
                },
                {
                    name: "CPV",
                    data: this.dailyCPV ? this.dailyCPV : [],
                },
                {
                    name: "ER",
                    data: this.dailyER ? this.dailyER : [],
                },
                {
                    name: "Reach",
                    data: this.dailyReach ? this.dailyReach : [],
                },
                {
                    name: "CPL",
                    data: this.dailyCPL ? this.dailyCPL : [],
                },
                {
                    name: "CPI",
                    data: this.dailyCPI ? this.dailyCPI : [],
                },
                {
                    name: "CPA",
                    data: this.dailyCPA ? this.dailyCPA : [],
                },
                {
                    name: "Engagements",
                    data: this.dailyEngagements ? this.dailyEngagements : [],
                },
                {
                    name: "CPE",
                    data: this.dailyCPE ? this.dailyCPE : [],
                },
            ];
            this.lineChartOptions = {
                ...this.lineChartOptions,
                ...{
                    xaxis: {
                        type: "datetime",
                        categories: this.chartDates,
                        labels: {
                            format: "MMM dd",
                        },
                    },
                },
            };
        },
        getCampaignCombinations() {
            let combinations = [];
            if (
                this.campaignObjective === "awareness" &&
                this.campaignType === "display"
            ) {
                //Client View - CPM, Impressions, Spend, Clicks, CTR
                //Mediapal/SuperAdmin - CPM, Impressions, Spend, Clicks, CTR, CPC
                //( Impressions vs CPM)(Clicks vs CTR)(Impressions vs Spend)
                //Make combinations an array of arrays with [[Impressions vs CPM], [Clicks vs CTR], [Impressions vs Spend]]
                // let impressions_clicks = this.series.filter((item) => {
                //     return item.name === "Impressions" || item.name === "Clicks";
                // });
                let clicks_ctr = this.series.filter((item) => {
                    return item.name === "Clicks" || item.name === "CTR";
                });
                let impressions_spend = this.series.filter((item) => {
                    return item.name === "Impressions" || item.name === "Spend";
                });
                combinations.push(clicks_ctr, impressions_spend);
            } else if (
                this.campaignObjective === "awareness" &&
                this.campaignType === "video"
            ) {
                //Client View - CPV, Impressions, Spend, Views(ncludeing 25%), VR, VTR
                //Mediapal View -  CPV, Impressions, Spend, Views(ncludeing 25%), VR, VTR
                //(Views vs CPV)(Spend vs CPV)( Impressions vs VTR)
                // let views_cpv = this.series.filter((item) => {
                //     return item.name === "Views" || item.name === "CPV";
                // });
                let spend_cpv = this.series.filter((item) => {
                    return item.name === "Spend" || item.name === "CPV";
                });
                let impressions_vtr = this.series.filter((item) => {
                    return item.name === "Impressions" || item.name === "VTR";
                });
                combinations.push(spend_cpv, impressions_vtr);
            } else if (
                this.campaignObjective === "awareness" &&
                this.campaignType === "engagement"
            ) {
                //Client View - CPE, Impressions, Spend, Engagements and Engagement Rate
                //Mediapal View -  CPE, Impressions, Spend, Engagements and Engagement Rate
                //( Impressions vs ER)(Engagements vs CPE)(Spend vs CPE)
                // let impressions_er = this.series.filter((item) => {
                //     return item.name === "Impressions" || item.name === "ER";
                // });
                let engagements_cpe = this.series.filter((item) => {
                    return item.name === "Engagements" || item.name === "CPE";
                });
                let spend_cpe = this.series.filter((item) => {
                    return item.name === "Spend" || item.name === "CPE";
                });
                combinations.push(engagements_cpe, spend_cpe);
            } else if (
                this.campaignObjective === "reach" &&
                this.campaignType === "display"
            ) {
                //Client View - CPM, Impressions,Spend, Reach, Frequency, Clicks & CTR
                //Mediapal View - CPM, Impressions,Spend, Reach & Frequency
                //(Impressions vs CPM)(Reach vs Frequency)(Spend vs CPM)
                let reach_frequency = this.series.filter((item) => {
                    return item.name === "Reach" || item.name === "Frequency";
                });
                let reach_spend = this.series.filter((item) => {
                    return item.name === "Reach" || item.name === "SPend";
                });
                combinations.push(reach_frequency, reach_spend);
            } else if (
                this.campaignObjective === "reach" &&
                this.campaignType === "video"
            ) {
                //Client View - CPM, Impressions,Spend, Reach, Frequency, Clicks & CTR
                //Mediapal View - CPM, Impressions,Spend, Reach & Frequency
                //(Impressions vs CPM)(Reach vs Frequency)(Spend vs CPM)
                let reach_frequency = this.series.filter((item) => {
                    return item.name === "Reach" || item.name === "Frequency";
                });
                let impressions_vtr = this.series.filter((item) => {
                    return item.name === "Impressions" || item.name === "VTR";
                });
                combinations.push(reach_frequency, impressions_vtr);
            } else if (
                this.campaignObjective === "reach" &&
                this.campaignType === "engagement"
            ) {
                //Client View - CPM, Impressions,Spend, Reach, Frequency, Clicks & CTR
                //Mediapal View - CPM, Impressions,Spend, Reach & Frequency
                //(Impressions vs CPM)(Reach vs Frequency)(Spend vs CPM)
                let reach_frequency = this.series.filter((item) => {
                    return item.name === "Reach" || item.name === "Frequency";
                });
                let spend_cpe = this.series.filter((item) => {
                    return item.name === "Spend" || item.name === "CPE";
                });
                combinations.push(reach_frequency, spend_cpe);
            } else if (
                this.campaignObjective === "traffic" &&
                this.campaignType === "display"
            ) {
                //Client View -  CPC, Spend & Clicks
                //Mediapal View - CPC, Clicks, Spend, Impressions, & CTR
                //(Spend vs CPC)(Clicks vs CPC)
                let spend_ctr = this.series.filter((item) => {
                    return item.name === "CTR" || item.name === "Spend";
                });
                let clicks_cpc = this.series.filter((item) => {
                    return item.name === "Clicks" || item.name === "CPC";
                });
                // let clicks_ctr = this.series.filter((item) => {
                //     return item.name === "Clicks" || item.name === "CTR";
                // });
                // let spend_ctr = this.series.filter((item) => {
                //     return item.name === "Spend" || item.name === "CTR";
                // });
                combinations.push(spend_ctr, clicks_cpc);
            } else if (
                this.campaignObjective === "traffic" &&
                this.campaignType === "video"
            ) {
                //Client View - CPC, Clicks, Spend
                //Mediapal View - CPC, Clicks, Spend, Impressions & CTR
                //(Spend vs CPC)(Clicks vs CPC)
                let spend_cpv = this.series.filter((item) => {
                    return item.name === "Spend" || item.name === "CPV";
                });
                let vtr_views = this.series.filter((item) => {
                    return item.name === "VTR" || item.name === "Views";
                });
                combinations.push(spend_cpv, vtr_views);
            } else if (
                this.campaignObjective === "leads" && this.campaignType === "display"
            ) {
                //Client View -  CPL, Leads, Spend
                //Mediapal View - CPL, Leads, Spend, Impressions
                //(Spend vs CPL)(Leads vs CPL)
                // let spend_impressions = this.series.filter((item) => {
                //     return item.name === "Spend" || item.name === "Impressions";
                // });
                // let impressions_leads = this.series.filter((item) => {
                //     return item.name === "Impressions" || item.name === "Leads";
                // });
                // let spend_leads = this.series.filter((item) => {
                //     return item.name === "Spend" || item.name === "Leads";
                //});
                let leads_cpl = this.series.filter((item) => {
                    return item.name === "Leads" || item.name === "CPL";
                });
                combinations.push(leads_cpl);
            } else if (
                this.campaignObjective === "app installs" &&
                this.campaignType === "display"
            ) {
                //Client View -  CPI, Installs, Spend
                //Mediapal View - CPI, Installs, Spend, Clicks, CTR & Impressions
                //(Spend vs CPI)(Installs vs CPI)
                // let spend_cpi = this.series.filter((item) => {
                //     return item.name === "Spend" || item.name === "CPI";
                // });
                let installs_cpi = this.series.filter((item) => {
                    return item.name === "Installs" || item.name === "CPI";
                });
                combinations.push(installs_cpi);
            } else if (
                this.campaignObjective === "product and consideration" &&
                this.campaignType === "video"
            ) {
                //Client View - CPV, Impressions, Spend, Views(including 25%), VTR & VR
                //Mediapal View - CPV, Impressions, Spend, Views(including 25%), VTR & VR
                //(Views vs CPV)(Spend vs CPV)( Impressions vs VTR)
                let views_cpv = this.series.filter((item) => {
                    return item.name === "View" || item.name === "CPV";
                });
                let spend_cpv = this.series.filter((item) => {
                    return item.name === "Spend" || item.name === "CPV";
                });
                let impressions_vtr = this.series.filter((item) => {
                    return item.name === "Impressions" || item.name === "VTR";
                });
                combinations.push(views_cpv, spend_cpv, impressions_vtr);
            } else if (
                this.campaignObjective === "conversion" &&
                this.campaignType === "display"
            ) {
                //Client View - CPA, Acquisitions, Spend
                //Mediapal View - CPA, Acquisitions, Spend & Impressions
                //(Spend vs CPA)(Acquisition vs CPA)
                // let spend_cpa = this.series.filter((item) => {
                //     return item.name === "Spend" || item.name === "CPA";
                // });
                let acquisition_cpa = this.series.filter((item) => {
                    return item.name === "Acquisitions" || item.name === "CPA";
                });
                combinations.push(acquisition_cpa);
            } else if (
                this.campaignObjective === "sales" &&
                this.campaignType === "display"
            ) {
                //Client View - CPA, Sales, Impressions & Spend
                //Mediapal View - CPA, Sales, Impressions & Spend
                //( Spend vs CPA)(Sales vs CPA)(Impressions vs CPA)
                let spend_cpa = this.series.filter((item) => {
                    return item.name === "Spend" || item.name === "CPA";
                });
                let sales_cpa = this.series.filter((item) => {
                    return item.name === "Sales" || item.name === "CPA";
                });
                let impressions_cpa = this.series.filter((item) => {
                    return item.name === "Impressions" || item.name === "CPA";
                });
                combinations.push(spend_cpa, sales_cpa, impressions_cpa);
            } else if (
                this.campaignObjective === "sales" &&
                this.campaignType === "video"
            ) {
                //Client View - CPA, Sales,Spend
                //Mediapal View - CPA, Sales, Impressions & Spend
                //(Spend vs CPA)(Sales vs CPA)
                let spend_cpa = this.series.filter((item) => {
                    return item.name === "Spend" || item.name === "CPA";
                });
                let sales_cpa = this.series.filter((item) => {
                    return item.name === "Sales" || item.name === "CPA";
                });
                combinations.push(spend_cpa, sales_cpa);
            } else if (
                this.campaignObjective === "sales" &&
                this.campaignType === "shopping"
            ) {
                //Client View - CPA, Sales,Spend
                //Mediapal View - CPA, Sales, Impressions & Spend
                //(Spend vs CPA)(Sales vs CPA)
                let spend_cpa = this.series.filter((item) => {
                    return item.name === "Spend" || item.name === "CPA";
                });
                let sales_cpa = this.series.filter((item) => {
                    return item.name === "Sales" || item.name === "CPA";
                });
                combinations.push(spend_cpa, sales_cpa);
            } else if (
                this.campaignObjective === "Local Store Visits" &&
                this.campaignType === "display"
            ) {
                //Client View -  CPM, Impressions & Spend
                //Mediapal View - CPM, Impressions & Spend
                //(Impressions vs CPM)(Spend vs CPM)
                let impressions_cpm = this.series.filter((item) => {
                    return item.name === "Impressions" || item.name === "CPM";
                });
                let spend_cpm = this.series.filter((item) => {
                    return item.name === "Spend" || item.name === "CPM";
                });
                combinations.push(impressions_cpm, spend_cpm);
            } else if (
                this.campaignObjective === "Local Store Visits" &&
                this.campaignType === "video"
            ) {
                //Client View -  CPM, Impressions & Spend
                //Mediapal View - CPM, Impressions & Spend
                //(Impressions vs CPM)(Spend vs CPM)
                let impressions_cpm = this.series.filter((item) => {
                    return item.name === "Impressions" || item.name === "CPM";
                });
                let spend_cpm = this.series.filter((item) => {
                    return item.name === "Spend" || item.name === "CPM";
                });
                combinations.push(impressions_cpm, spend_cpm);
            } else {
                let clicks_ctr = this.series.filter((item) => {
                    return item.name === "Clicks" || item.name === "CTR";
                });
                let impressions_clicks = this.series.filter((item) => {
                    return item.name === "Impressions" || item.name === "Clicks";
                });
                combinations.push(clicks_ctr, impressions_clicks);
            }
            let filteredCombinations = combinations.filter(combination => {
                return combination.every(item => Array.isArray(item.data) && item.data.length > 0);
            });

            return filteredCombinations;
        },
    },
};
</script>
  
<style></style>
  