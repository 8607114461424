<template>
  <div>
    <v-card height="300" flat>
      <v-card-title class="py-2 d-flex justify-space-between">
        <h6>Users by Device Price</h6>
      </v-card-title>

      <v-divider></v-divider>

      <v-simple-table v-if="comparisons.length" dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Price Range</th>
              <th class="text-left"></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(item, index) in comparisons" :key="index">
              <td>
                <h4 class="font-weight-bold">{{ item?.price_range }}</h4>
              </td>
              <td class="text-center">
                <div class="d-flex align-center">
                  <v-progress-linear
                    class="mr-2"
                    rounded
                    :value="item?.percentage"
                    height="7"
                    color="#6092c0"
                  ></v-progress-linear>

                  <h6>{{ item?.percentage }}</h6>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <div v-else>
        <v-card height="250" flat class="d-flex align-center justify-center">
          <div class="text-center py-10">
            <v-icon x-large>mdi-alert-circle-outline</v-icon>
            <p class="text-caption text-disabled">No data available</p>
          </div>
        </v-card>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      comparisons: [],
      priceRanges: [
        {
          price_range: "$0 - $100",
          models: [
            "sm-a032f",
            "sm-a042f",
            "TECNO CI6",
            "TECNO CG6",
            "TECNO CK6",
            "TECNO CD7",
            "TECNO CD6j",
            "generic",
          ],
        },
        {
          price_range: "$101 - $200",
          models: [
            "SM-A135F",
            "SM-A127F",
            "SM-A145F",
            "SM-A047F",
            "SM-A235F",
            "SM-A125F",
            "Nokia C32",
            "TECNO KI5k",
            "SM-A245F",
            "SM-A217F",
            "Nokia G21",
            "Nokia C31",
            "TECNO KF7j",
            "TECNO BF6",
            "TECNO KI7",
            "SM-A225F",
            "SM-A325F",
            "SM-A035F",
            "Infinix X669",
            "TECNO KJ5",
            "CPH2015",
            "CPH2217",
            "CPH2591",
            "CPH2477",
            "TECNO KF6i",
          ],
        },
        {
          price_range: "$201 - $300",
          models: [
            "SM-A032F",
            "sm-a127f",
            "SM-A546E",
            "SM-A536E",
            "SM-A125F",
            "CPH2239",
            "CPH2565",
            "TECNO KG5j",
            "SM-A346E",
            "SM-A155F",
            "CPH2481",
            "CPH2505",
            "CPH2531",
            "SM-A037F",
            "SM-A525F",
            "CPH2385",
            "Infinix X688B",
            "SM-A336E",
          ],
        },
        {
          price_range: "$301 - $400",
          models: [
            "SM-A217F",
            "sm-a135f",
            "sm-a145f",
            "CPH2473",
            "SM-S908E",
            "CPH2577",
            "TECNO KG5j",
            "c22",
            "TECNO BG6",
            "21061119ag",
            "CPH2481",
            "CPH2505",
            "CPH2565",
            "23106RN0DA",
            "neon ray pro",
            "x657",
          ],
        },
        {
          price_range: "$401 - $500",
          models: [
            "SM-S918B",
            "CPH2349",
            "CPH2471",
            "CPH2333",
            "m2006c3lg",
            "bf6",
            "BF7",
            "kc8",
            "ck6",
          ],
        },
        {
          price_range: "$501+",
          models: ["iphone", "SM-A736B", "SM-A145F", "CPH2217", "SM-S908E"],
        },
      ],
      colors: [
        "#00E396",
        "#f3b415",
        "#f27036",
        "#d36085",
        "#6092c0",
        "#7e2553",
        "#2a2553",
        "#2a2",
      ],
    }
  },
  props: {
    campaign: {
      type: String,
      validator: function (value) {
        return Number(value)
      },
    },
  },
  computed: {
    device_models() {
      return this.$store.state.dsp.device_model_distribution
    },
  },
  watch: {
    device_models: {
      handler: function (deviceData) {
        // const totalDocCount = val.reduce((acc, item) => acc + item.doc_count, 0)

        let data = deviceData.map((item) => {
          return {
            name: item.key,
            data: item.doc_count,
          }
        })

        this.comparisons = this.calculatePriceRangeData(this.priceRanges, data)
      },
      deep: true,
    },
  },
  methods: {
    calculatePriceRangeData(priceRanges, devicesData) {
      const totalData = devicesData.reduce(
        (acc, device) => acc + device.data,
        0
      )

      return priceRanges.map((priceRange) => {
        const modelsData = priceRange.models.map((model) => {
          const device = devicesData.find((d) => d.name === model)
          return device ? device.data : 0
        })
        const priceRangeTotalData = modelsData.reduce(
          (acc, data) => acc + data,
          0
        )
        const percentage = (priceRangeTotalData / totalData) * 100

        //add color to price range
        priceRange.color = this.colors[priceRanges.indexOf(priceRange)]

        return {
          price_range: priceRange.price_range,
          total_data: priceRangeTotalData.toFixed(2),
          percentage: percentage.toFixed(2) + "%",
          color: priceRange.color,
        }
      })
    },
  },
}
</script>
