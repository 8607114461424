<template>
  <v-row>
    <v-col cols="2"><v-select class="shrink" outlined dense label="Metric" @change="selectSeries()"
        v-model="select_series" :items="__selectSeries1Data" hide-details
        :menu-props="{ bottom: true, offsetY: true }"></v-select>
    </v-col>
    <v-col cols class="pl-0 pr-0 shrink">
      <div class="d-flex align-center text-center">
        <div class="mt-2">vs</div>
      </div>
    </v-col>
    <v-col cols="2"><v-select class="shrink" outlined dense label="Metric" @change="selectSeries2()"
        v-model="select_series2" :items="__selectSeries2Data" hide-details
        :menu-props="{ bottom: true, offsetY: true }"></v-select>
    </v-col>

    <v-col cols="3" class="pl-0">
      <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
        min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="dateRangeText" label="Period" readonly v-bind="attrs" v-on="on" class="shrink" outlined
            prepend-inner-icon="mdi-calendar" dense hide-details></v-text-field>
        </template>
        <v-date-picker v-model="dates" scrollable range no-title @change="selectDateRange()" :min="this.startDate"
          :max="this.campaignEndDate">
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="12">
      <div>
        <apexchart ref="chart" type="line" height="350" v-if="apexLoading && campaignObjective"
          :options="lineChartOptions" :series="series"></apexchart>
      </div>
    </v-col>
    <!-- <v-col>
      <div ref="chartsContainer">
        <div v-for="(combination, index) in combinations" :key="index">
          <div class="chart-container" :ref="chartRefs[index]"></div>
        </div>
      </div>
    </v-col> -->
  </v-row>
</template>

<script>
import config from "@/apex-config";
// import ApexChart from "vue-apexcharts";
// import ApexCharts from "apexcharts";
export default {
  props: {
    stats: {
      type: Array,
    },
    selectCharts: {
      type: Array,
      required: true,
    },
    campaignObjective: {
      type: String,
    },
    campaignType: {
      type: String,
    },
    campaignEndDate: {
      type: String,
    },
    date_Leads: {},
    campaign_statistics: {}
  },

  data() {
    return {
      config,
      apexLoading: false,
      select_series: "",
      select_series2: "",
      // select_charts: ["Impressions", "Spend", "Clicks", "CTR", "CPM"],
      dates: [],
      menu: false,
      startDate: null,
      endDate: null,
      lineChartOptions: {
        chart: {
          type: "line",
          height: 300,
          zoom: {
            enabled: true,
          },
          toolbar: {
            show: false,
          },
          animation: {
            enabled: false,
          },
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
        },
        noData: {
          text: "No Data...",
          align: "center",
          verticalAlign: "middle",
        },
        colors: ['#1876d2', '#d36085'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [2, 2],
          dashArray: [0, 0],
          curve: ["smooth", "smooth"],
        },

        markers: {
          size: 3,
          style: "hollow",
          hover: {
            sizeOffset: 4,
          },
        },
        xaxis: {
          type: "category",
        },

        yaxis: [
          {
            opposite: false,
            show: true,
            showForNullSeries: false,
            decimalsInFloat: 2,
          },
          {
            opposite: true,
            show: true,
            showForNullSeries: false,
            decimalsInFloat: 2,
          },
        ],

        legend: {
          opposite: false,
          show: true,
          showForNullSeries: false,
          position: "top",
          horizontalAlign: "left",
          itemMargin: {
            horizontal: 5,
            vertical: 5,
          },
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
      },
      series: [],
      combinations: [],
      charts: [],
      user_Series: [],
      filtered_Combinations: [],
      dailyImpressions: [],
      dailySpend: [],
      dailyLeads: [],
      dailyCPC: [],
      dailyCPL: [],
      dailyEngagements: [],
      dailyCPE: [],
      dailyER: []
    };
  },
  watch: {
    stats() {
      this.generateChartData();
      // this.user_Series.push({
      //   name: this.getSeries().name,
      //   data: this.getSeries().data,
      // });
      // this.user_Series.push({
      //   name: this.getSeries2().name,
      //   data: this.getSeries2().data,
      // });
    },
    selectCharts() {
      this.select_series;
    },
  },
  computed: {
    dateRangeText() {
      return this.dates.join("  -  ");
    },
    chartRefs() {
      return this.combinations.map((_, index) => `chartContainer-${index}`);
    },
    chartObjective() {
      let chart = "Impressions";
      if (
        this.campaignObjective === "leads" && this.campaignType === "display"
      ) {
        chart = "Spend";
      } else if (this.campaignObjective == "traffic" && this.campaignType === "display") {
        chart = "CTR%";
      } else if (this.campaignObjective == "traffic" && this.campaignType === "video") {
        chart = "Views"
      } else if (this.campaignObjective == "awareness" && this.campaignType === "display") {
        chart = "Impressions";
      } else if (this.campaignObjective == "awareness" && this.campaignType === "video") {
        chart = "Views";
      } else if (this.campaignObjective == "awareness" && this.campaignType === "engagement") {
        chart = "Impressions";
      } else if (this.campaignObjective == "reach" && this.campaignType === "display") {
        chart = "Impressions";
      } else if (this.campaignObjective == "reach" && this.campaignType === "video") {
        chart = "Views";
      } else if (this.campaignObjective == "reach" && this.campaignType === "engagement") {
        chart = "Impressions";
      } else if (this.campaignObjective == "app_installs" && this.campaignType === "display") {
        chart = "Spend";
      } else if (this.campaignObjective == "conversions" && this.campaignType === "video") {
        chart = "Spend";
      }
      return chart;
    },
    __selectSeries1Data() {
      return this.selectCharts.filter((item) => {
        return item != this.select_series2;
      });
    },

    __selectSeries2Data() {
      return this.selectCharts.filter((item) => {
        return item != this.select_series;
      });
    },
  },
  mounted() {
    setTimeout(() => {
      this.apexLoading = true;
      this.select_series = this.campaignObjective;
      this.generateChartData();
      if (this.campaignObjective === "traffic") {
        if (this.campaignType === "display") {
          this.select_series = "CTR%"
          this.select_series2 = "Clicks";
          this.series.filter(item => {
            return item.name === "Clicks" ||
              item.name === "CTR%"
          });
        } else if (this.campaignType === "video") {
          this.select_series = "Views"
          this.select_series2 = "CPV";
          this.series.filter(item => {
            return item.name === "Views" ||
              item.name === "CPV"
          });
        }
      } else if (this.campaignObjective === "awareness") {
        if (this.campaignType === "display") {
          this.select_series = "Impressions"
          this.select_series2 = "CTR%";
          this.series.filter(item => {
            return item.name === "Impressions" ||
              item.name === "CTR%"
          });
        } else if (this.campaignType === "video") {
          this.select_series = "Views"
          this.select_series2 = "CPV";
          this.series.filter(item => {
            return item.name === "Views" ||
              item.name === "CPV"
          });
        } else if (this.campaignType === "engagement") {
          this.select_series = "Impressions"
          this.select_series2 = "ER";
          this.series.filter(item => {
            return item.name === "Impressions" ||
              item.name === "ER"
          });
        }
      } else if (this.campaignObjective === "reach") {
        if (this.campaignType === "display") {
          this.select_series = "Impressions"
          this.select_series2 = "Reach";
          this.series.filter(item => {
            return item.name === "Impressions" ||
              item.name === "Reach"
          });
        } else if (this.campaignType === "video") {
          this.select_series = "Views"
          this.select_series2 = "CPV";
          this.series.filter(item => {
            return item.name === "Views" ||
              item.name === "CPV"
          });
        } else if (this.campaignType === "engagement") {
          this.select_series = "Impressions"
          this.select_series2 = "ER";
          this.series.filter(item => {
            return item.name === "Impressions" ||
              item.name === "ER"
          });
        }
      } else if (this.campaignObjective === "leads") {
        if (this.campaignType === "display") {
          this.select_series = "Spend"
          this.select_series2 = "CPL";
          this.series.filter(item => {
            return item.name === "Spend" ||
              item.name === "CPL"
          });
        }
      } else if (this.campaignObjective === "app installs") {
        if (this.campaignType === "display") {
          this.select_series = "Spend"
          this.select_series2 = "CPI";
          this.series.filter(item => {
            return item.name === "Spend" ||
              item.name === "CPI"
          });
        }
      } else if (this.campaignObjective === "conversion") {
        if (this.campaignType === "display") {
          this.select_series = "Spend"
          this.select_series2 = "CPA";
          this.series.filter(item => {
            return item.name === "Spend" ||
              item.name === "CPA"
          });
        }
      } else {
        this.select_series = "Impressions"
        this.select_series2 = "Spend";
        this.series.filter(item => {
          return item.name === "Impressions" ||
            item.name === "Spend"
        });
      }
    })
  },
  methods: {
    selectDateRange() {
      // reverses dates if user selects dates in reverse order
      if (this.dates.at(-1) < this.dates.at(0)) {
        this.dates = this.dates.reverse();
      }

      //Emit this.dates to parent component
      this.$emit("dateRange", this.dates);

      this.$refs.chart.zoomX(
        new Date(this.dates.at(0)).getTime(),
        new Date(this.dates.at(-1)).getTime()
      );
    },
    getSeries() {
      const selectedSeries = this.series.find((series) => {
        return series.name === this.select_series;
      });

      return selectedSeries;
    },
    getSeries2() {
      if (this.select_series2) {
        const selectedSeries = this.series.find((series) => {
          return series.name === this.select_series2;
        });

        return selectedSeries;
      } else return { name: "", data: [] };
    },

    selectSeries() {
      this.updateChart();
    },
    selectSeries2() {
      this.updateChart();
    },
    updateChart() {
      if (this.select_series == this.select_series2) {
        this.$refs.chart.updateSeries([
          { name: this.getSeries().name, data: this.getSeries().data },
          { name: "", data: [] },
        ]);
      } else {
        this.lineChartOptions = {
          ...this.lineChartOptions,
          ...{
            xaxis: {
              type: "datetime",
              categories: this.chartDates,
              labels: {
                format: "MMM dd yy",
              },
            },
            yaxis: [
              {
                opposite: false,
                show: true,
                showForNullSeries: false,
                decimalsInFloat: this.getSeries().name == 'Impressions' || this.getSeries().name == 'Clicks' || this.getSeries2().name == 'Leads' ? 0 : 2,
              },
              {
                opposite: true,
                show: true,
                showForNullSeries: false,
                decimalsInFloat: this.getSeries2().name == 'Impressions' || this.getSeries2().name == 'Clicks' || this.getSeries2().name == 'Leads' ? 0 : 2
              },
            ],
          },
        };
        this.$refs.chart.updateSeries([
          { name: this.getSeries().name, data: this.getSeries().data },
          { name: this.getSeries2().name, data: this.getSeries2().data },
        ]);
      }
    },

    generateChartData() {
      const filteredStats = this.stats.filter((stat) => {
        // if (!this.superAdmin) {
        //   return stat.total_impressions > 10000; // hide campaigns with less than 10k impressions from client view
        // }
        return stat.total_impressions > 0;
      });
      this.dailyImpressions = filteredStats.map((value) =>
        Number.parseInt(value.total_impressions)
      );

      this.dailySpend = filteredStats.map((value) =>
        Number.parseFloat(value.total_spend)
      );

      this.dailyClicks = filteredStats.map((value) =>
        Number.parseInt(value.total_clicks)
      );

      this.dailyCTR = filteredStats.map((value) =>
        Number.parseFloat(value.average_ctr)
      );
      this.dailyCPM = filteredStats.map((value) =>
        Number.parseFloat(value.average_cpm)
      );
      this.dailyCPC = filteredStats.map((value) => {
        const totalSpend = parseFloat(value.total_spend);
        const totalClicks = value.total_clicks;
        return totalClicks !== 0 ? totalSpend / totalClicks : 0;
      });
      this.dailyCPV = filteredStats.map((value) =>
        Number.parseFloat(value.views ? value.views / value.total_spend : 0)
      );
      this.dailyViews = filteredStats.map((value) =>
        value.views ? Number.parseFloat(value.views) : 0
      );
      this.dailyVTR = filteredStats.map((value) =>
        value.viewThroughs ? Number.parseFloat(value.viewThroughs / value.total_impressions) * 100 : 0
      );
      this.dailyCPL = filteredStats.map((item) => { if (item.total_clicks !== 0) { return parseFloat(item.total_spend) / parseFloat(item.total_clicks); } else { return 0; } });

      let statsDates = filteredStats.map(entry => entry.date);
      this.dailyEngagements = statsDates.map(date => {
        const engagementsData = this.campaign_statistics.engagements ? this.campaign_statistics.engagements.events_by_date[date] : 0;
        return engagementsData ? engagementsData.length : 0;
      });

      this.dailyCPE = filteredStats.map(stat => {
        const totalSpend = parseFloat(stat.total_spend);
        const dateEngagements = this.campaign_statistics.engagements ? this.campaign_statistics.engagements.events_by_date[stat.date] : 0;
        const sumOfEngagements = dateEngagements ? dateEngagements.length : 0;
        return sumOfEngagements > 0 ? totalSpend / sumOfEngagements : 0;
      });

      this.dailyER = filteredStats.map(stat => {
        const totalImpressions = parseFloat(stat.total_impressions);
        const dateEngagements = this.campaign_statistics.engagements ? this.campaign_statistics.engagements.events_by_date[stat.date] : 0;
        const sumOfEngagements = dateEngagements ? dateEngagements.length : 0;
        return sumOfEngagements > 0 ? (sumOfEngagements / totalImpressions) * 100 : 0;
      });

      //Engagements
      //dailyReach
      //dailyCPL
      //dailyCPI
      //dailyCPA

      this.chartDates = filteredStats.map((value) => value.date);

      this.startDate = this.chartDates.at(0);
      this.endDate = this.chartDates.at(-1);

      this.dates = [this.startDate, this.endDate];

      this.series = [
        {
          name: "Impressions",
          data: this.dailyImpressions ? this.dailyImpressions : [],
        },
        {
          name: "Clicks",
          data: this.dailyClicks ? this.dailyClicks : [],
        },
        {
          name: "CTR%",
          data: this.dailyCTR ? this.dailyCTR : [],
        },
        {
          name: "CPM",
          data: this.dailyCPM ? this.dailyCPM : [],
        },
        {
          name: "Leads",
          data: this.date_Leads ? Object.values(this.date_Leads) : []
        },
        {
          name: "Spend",
          data: this.dailySpend ? this.dailySpend : [],
        },
        {
          name: "CPC",
          data: this.dailyCPC ? this.dailyCPC : [],
        },
        {
          name: "Views",
          data: this.dailyViews ? this.dailyViews : [],
        },
        {
          name: "CPV",
          data: this.dailyCPV ? this.dailyCPV : [],
        },
        {
          name: "ER",
          data: this.dailyER ? this.dailyER : [],
        },
        {
          name: "Reach",
          data: this.dailyReach ? this.dailyReach : [],
        },
        {
          name: "CPL",
          data: this.dailyCPL ? this.dailyCPL : [],
        },
        {
          name: "CPI",
          data: this.dailyCPI ? this.dailyCPI : [],
        },
        {
          name: "CPA",
          data: this.dailyCPA ? this.dailyCPA : [],
        },
        {
          name: "Engagements",
          data: this.dailyEngagements ? this.dailyEngagements : [],
        },
        {
          name: "CPE",
          data: this.dailyCPE ? this.dailyCPE : [],
        },
      ];
      this.lineChartOptions = {
        ...this.lineChartOptions,
        ...{
          xaxis: {
            type: "datetime",
            categories: this.chartDates,
            labels: {
              format: "MMM dd",
            },
          },
          yaxis: [
            {
              opposite: false,
              show: true,
              showForNullSeries: false,
              decimalsInFloat: this.select_series == 'Impressions' || this.select_series == 'Clicks' || this.getSeries2().name == 'Leads' ? 0 : 2,
            },
            {
              opposite: true,
              show: true,
              showForNullSeries: false,
              decimalsInFloat: this.select_series_2 == 'Impressions' || this.select_series_2 == 'Clicks' || this.getSeries2().name == 'Leads' ? 0 : 2
            },
          ],
        },
      };
      setTimeout(() => {
        this.select_series = this.chartObjective;
        this.selectSeries2();
        this.selectSeries();
      });
    },
  },
};
</script>

<style></style>
