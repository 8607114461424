<template>
    <div>
        <v-card height="300" id="container" flat>

            <v-card-title class="d-flex align-center py-1">
                <h6 class="mr-auto">Environment</h6>
            </v-card-title>

            <v-divider></v-divider>

            <v-row v-if="environment_distribution.length">
                <v-col cols="7">
                    <v-card-text>
                        <apexchart height="250" type="donut" :options="chartOptions" :series="series"></apexchart>
                    </v-card-text>
                </v-col>

                <v-col class="d-flex justify-center align-center" cols="5">
                    <v-card-text>

                        <v-card-title class="overline grey--text">
                            App vs Web
                        </v-card-title>

                        <v-card-subtitle class="black--text text-caption">

                            <div v-for="(item, index) in environment_distribution" :key="index" class="d-flex align-center">
                                <v-icon :color="chartOptions.colors[index]" left x-small>mdi-circle</v-icon>{{ `${item.key}:
                                                                ${item?.doc_count?.toLocaleString()}` }}
                            </div>

                        </v-card-subtitle>
                    </v-card-text>
                </v-col>
            </v-row>

            <div v-else>
                <v-card height="255" flat class="d-flex align-center justify-center">
                    <div class="text-center py-10">
                        <v-icon x-large>mdi-alert-circle-outline</v-icon>
                        <p class="text-caption text-disabled">No data available</p>
                    </div>
                </v-card>
            </div>

        </v-card>


    </div>
</template>

<script>
export default {
    data() {
        return {
            series: [44, 55],
            chartOptions: {
                chart: {
                    height: 250,
                    width: "100%",
                    type: 'donut',
                },
                legend: {
                    show: false,
                    floating: true,
                    fontSize: '14px',
                    position: 'left',
                    offsetX: -20,
                    offsetY: -8,
                    labels: {
                        useSeriesColors: false,
                    },
                    markers: {
                        size: 0
                    },
                    itemMargin: {
                        vertical: 3
                    }
                },
                plotOptions: {
                    pie: {
                        donut: {
                            size: "50%"
                        }
                    }
                },
                colors: ['#77c1ab', '#6092c0', '#d36085', '#00E396', '#80c7fd'],
                labels: [],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },
        }
    },
    props: {
        campaign: {
            type: String,
            validator: function (value) {
                return Number(value);
            }
        },
    },

    watch: {
        environment_distribution: {
            handler: function (val) {
                // Extracting keys array
                const environment = val.map(item => item.key);

                // Extracting document counts array
                const data = val.map(item => item.doc_count);


                //set that to series
                this.series = data;

                // //set that to chart options
                this.chartOptions = {
                    ...this.chartOptions,
                    labels: environment
                };
            },
            deep: true
        },
    },
    mounted() {
        this.getEnvironmentDistribution();
    },

    computed: {
        environment_distribution() {
            return this.$store.state.dsp.environment_distribution
        }
    },

    methods: {
        //Get top carriers
        async getEnvironmentDistribution() {

            this.loading = true;

            try {

                let body = {
                    "campaign": this.campaign,
                    "body": {
                        "size": 0,
                        "query": {},
                        "aggs": {
                            "test_histogram": {
                                "terms": {
                                    "field": "bidtype.keyword",
                                    "size": 10
                                }
                            }
                        }
                    }
                }

                await this.$store.dispatch("dsp/getEnvironmentDistribution", body);

            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
    }
}
</script>