<template>
    <div>
        <v-navigation-drawer :value="isBillingModalOpen" @input="handleDrawerModelValueUpdate" fixed temporary right
            width="40%">
            <v-card flat>
                <v-card-title class="py-2 pr-3 justify-space-between">
                    <h6 class="text-uppercase">Campaign Billing</h6>
                    <v-btn icon @click="dismiss">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-subtitle>
                    <h4>
                        {{ campaign?.campaign.name }}
                    </h4>
                </v-card-subtitle>
                <v-divider></v-divider>

                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-card-text>
                        <v-row class="flex-column">

                            <v-col>
                                <v-select :rules="clientRules"
                                    :menu-props="{ maxHeight: '400', bottom: true, offsetY: true }" hide-details="auto"
                                    outlined dense :items="__customers" item-text="display_name" return-object
                                    label="Select Client" v-model="customer" placeholder="Select client"></v-select>
                            </v-col>

                            <v-col>
                                <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                                    transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field outlined dense hide-details="auto" v-model="date" label="Due Date"
                                            readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date" @input="menu2 = false"></v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col>
                                <v-text-field hide-details="auto" text="number" outlined dense v-model="amount"
                                    label="Amount" :rules="amountRules"></v-text-field>
                            </v-col>

                            <v-col>
                                <v-select :menu-props="{ maxHeight: '400', bottom: true, offsetY: true }" v-model="currency"
                                    hide-details="auto" outlined dense :items="['KES', 'USD']" label="Select Currency"
                                    placeholder="Select currency"></v-select>
                            </v-col>

                            <v-col>
                                <v-select :menu-props="{ maxHeight: '400', bottom: true, offsetY: true }" v-model="billingType"
                                    hide-details="auto" outlined dense :items="['One time', 'Recurring']" label="Billing type"
                                    placeholder="Billing type"></v-select>
                            </v-col>

                            <v-col>
                                <v-select :menu-props="{ maxHeight: '400', bottom: true, offsetY: true }" v-model="currency"
                                    hide-details="auto" outlined dense :items="['One time', 'Recurring']" label="Billing type"
                                    placeholder="Billing type"></v-select>
                            </v-col>

                            <v-col>
                                <v-select :menu-props="{ maxHeight: '400', bottom: true, offsetY: true }" v-model="billingFrequency"
                                    hide-details="auto" outlined dense :items="['Monthly', 'Bi-Monthly', 'Quarterly']" label="Billing Frequency"
                                    placeholder="Billing Frequency"></v-select>
                            </v-col>

                            <v-col>
                                <v-text-field hide-details="auto" outlined dense v-model="description"
                                    label="Invoice Description"></v-text-field>
                            </v-col>

                            <v-col>
                                <v-textarea v-model="notes" filled name="input-7-4" label="Invoice notes"></v-textarea>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-form>
            </v-card>

            <template #append>
                <v-card>
                    <v-card-text>
                        <v-btn :disabled="loading" :loading="loading" type="submit" @click="submitForm" block depressed color="primary" class="mt-2">Submit</v-btn>
                    </v-card-text>
                </v-card>
            </template>
        </v-navigation-drawer>
    </div>
</template>
  
<script>

export default {
    props: {
        isBillingModalOpen: {
            type: Boolean,
            required: true,
        },
        campaign: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            valid: true,
            amount: null,
            clientsLoading: false,
            loading: false,
            currency: "USD",
            customer: null,
            billingType: 'One time',
            billingFrequency: 'Monthly',
            amountRules: [
                value => {
                    if (value && value > 1) return true

                    return 'Amount must be greater that 0.'
                },
            ],
            clientRules: [
                value => !!value || 'Client is required',
            ],
            description: '',
            notes: '',
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu2: false,
            lastNameRules: [
                value => {
                    if (/[^0-9]/.test(value)) return true

                    return 'Last name can not contain digits.'
                },
            ],
        };
    },
    watch: {
        campaign() {
            this.amount = Math.trunc(this.campaign?.campaign?.total_budget);
        },
        isBillingModalOpen() {
            if (this.isBillingModalOpen) {
                this.getQBCustomers();
            }
        },
    },
    methods: {
        dismiss() {
            this.$emit("dismiss");
        },
        handleDrawerModelValueUpdate(value) {
            if (value == false) {
                this.$emit("dismiss");
            }
        },
        submitForm() {
            const isvalid = this.$refs.form.validate();

            if (isvalid) {
                let data = {
                    type: "dsp",
                    due_date: this.date,
                    amount: this.amount,
                    currency: this.currency,
                    description: this.description,
                    notes: this.notes,
                    customer: this.customer?.id,
                    campaign: this.campaign?.campaign?.id,
                    billing_type: this.billingType,
                    billing_frequency: this.billingFrequency,
                };

                this.createCampaignBillingMetadata(data);
            }

        },

        async createCampaignBillingMetadata(body) {
            this.loading = true;

            try {
                await this.$store.dispatch("billing/createCampaignBillingMetadata", body);

                this.$notify({
                    title: "Campaign billing set successfully",
                    group: "success",
                });

                this.dismiss();

            } catch (error) {
                this.$notify({
                    title: error?.message,
                    group: "errors",
                });
            } finally {
                this.loading = false;
            }
        },

        async getQBCustomers() {
            this.clientsLoading = true;
            try {
                await this.$store.dispatch("billing/getCustomers");
            } catch (error) {
                console.log(error);
            } finally {
                this.clientsLoading = false;
            }
        },
    },
    computed: {
        __isDrawerOpen() {
            return this.isBillingModalOpen;
        },
        __customers() {
            return this.$store.state.billing.customers;
        },
    },
};
</script>
  