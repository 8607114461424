<template>
    <div>
        <v-navigation-drawer :value="isInstallsModalOpen" @input="handleDrawerModelValueUpdate" fixed temporary right
            width="40%">
            <v-card flat>
                <v-card-title class="py-2 pr-3 justify-space-between">
                    <h6 class="text-uppercase">Update App Installs</h6>
                    <v-btn icon @click="dismiss">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-subtitle>
                    <h4>
                        {{ campaign?.campaign?.name }}
                    </h4>
                </v-card-subtitle>
                <v-divider></v-divider>

                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-card-text>
                        <v-row class="flex-column">
            
                            <v-col>
                                <v-text-field placeholder="Enter the number of installs" hide-details="auto" text="number" outlined dense v-model="installs"
                                    label="Installs" :rules="installRules"></v-text-field>
                            </v-col>
                            
                        </v-row>
                    </v-card-text>
                </v-form>
            </v-card>

            <template #append>
                <v-card>
                    <v-card-text>
                        <v-btn :disabled="loading" :loading="loading" type="submit" @click="submitForm" block depressed color="primary" class="mt-2">Submit</v-btn>
                    </v-card-text>
                </v-card>
            </template>
        </v-navigation-drawer>
    </div>
</template>
  
<script>

export default {
    props: {
        isInstallsModalOpen: {
            type: Boolean,
            required: true,
        },
        campaign: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            valid: true,
            installs: null,
            loading: false,
            installRules: [
                value => !!value || 'You must enter the number of installs',
            ],
        };
    },
    watch: {
        campaign() {
            this.installs = Math.trunc(this.campaign?.app_installs);
        },
    },
    methods: {
        dismiss() {
            this.$emit("dismiss");
        },
        handleDrawerModelValueUpdate(value) {
            if (value == false) {
                this.$emit("dismiss");
            }
        },
        submitForm() {

            const isvalid = this.$refs.form.validate();

            if (isvalid) {
                let data = {
                    campaign_id: this.campaign?.campaign?.id,
                    installs: this.installs,
                };

                this.updateCampaignAppInstalls(data);
            }

        },

        async updateCampaignAppInstalls(body) {

            this.loading = true;

            try {
                await this.$store.dispatch("dsp/updateCampaignAppInstalls", body);

                this.$notify({
                    title: "Installs updated successfully",
                    group: "success",
                });

                this.$emit("dismiss");

            } catch (error) {
                this.$notify({
                    title: error?.message,
                    group: "errors",
                });
            } finally {
                this.loading = false;
            }
        },
    },
    computed: {
        
    },
};
</script>
  