<template>
    <v-card flat height="300">
        <v-card-title class="d-flex align-center py-1">
            <h6>Top Carriers</h6>
        </v-card-title>

        <v-divider></v-divider>

        <v-row v-if="top_carriers.length">
            <v-col class="d-flex justify-center align-center" cols="6">
                <v-card-text>
                    <v-card-title class="overline grey--text">
                        Carrier Distribution
                    </v-card-title>
                    <v-card-subtitle class="black--text text-caption">

                        <div v-for="(item, index) in labelData" :key="index" class="d-flex align-center">
                            <v-icon left x-small>mdi-circle</v-icon>{{ `${item.carrier}: ${item?.value}` }}
                        </div>

                    </v-card-subtitle>
                </v-card-text>
            </v-col>

            <v-col cols="6">
                <apexchart height="250" type="pie" :options="chartOptions" :series="series"></apexchart>
            </v-col>
        </v-row>

        <div v-else>
            <v-card height="255" flat class="d-flex align-center justify-center">
                <div class="text-center py-10">
                    <v-icon x-large>mdi-alert-circle-outline</v-icon>
                    <p class="text-caption text-disabled">No data available</p>
                </div>
            </v-card>
        </div>

    </v-card>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            series: [],
            labelData: [],
            chartOptions: {
                chart: {
                    width: '100%',
                    type: 'pie',
                },
                labels: [],
                theme: {
                    monochrome: {
                        enabled: false
                    }
                },
                plotOptions: {
                    pie: {
                        dataLabels: {
                            offset: -5
                        }
                    }
                },
                dataLabels: {
                    formatter(val, opts) {
                        const name = opts.w.globals.labels[opts.seriesIndex]
                        return [name, val.toFixed(1) + '%']
                    }
                },
                legend: {
                    show: false
                }
            },
        }
    },
    props: {
        campaign: {
            type: String,
            validator: function (value) {
                return Number(value);
            }
        },
    },

    watch: {
        top_carriers: {
            handler: function (val) {
                // Extracting keys array
                const carriers = val.map(item => item.key);

                const replacementName = "Other";


                const carriernames = carriers.map(key => {
                    const carrier = this.all_carriers.find(c => c.mobile_carrier_id === parseInt(key));
                    return carrier ? carrier.carrier_name : replacementName;
                });

                // Extracting document counts array
                const data = val.map(item => item.doc_count);

                //Create an array with carrier name and data
                this.labelData = carriernames.map((carrier, index) => {

                    const formattedValue = Number(data[index]).toLocaleString();

                    return { carrier: carrier, value: formattedValue };
                });

                //get the latest 7 items from labelData
                this.labelData = this.labelData.slice(0, 7);

                //set that to series
                this.series = data;

                // //set that to chart options
                this.chartOptions = {
                    ...this.chartOptions,
                    labels: carriernames
                };
            },
            deep: true
        },
    },
    mounted() {
        this.getTopCarriers();
    },

    computed: {
        all_carriers() {
            return this.$store.state.dsp.static_dsp_data?.mobile_carriers_codes;
        },
        top_carriers() {
            return this.$store.state.dsp.top_carriers
        }
    },

    methods: {
        //Get top carriers
        async getTopCarriers() {

            this.loading = true;

            try {

                let body = {
                    "campaign": this.campaign,
                    "body": {
                        "size": 0,
                        "query": {},
                        "aggs": {
                            "test_histogram": {
                                "terms": {
                                    "field": "r_device.carrier.keyword",
                                    "size": 10
                                }
                            }
                        }
                    }
                }

                await this.$store.dispatch("dsp/getTopCarriers", body);

            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
    }
}
</script>