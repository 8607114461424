<template>
    <v-card flat height="300">
        <v-card-title class="d-flex align-center justify-space-between py-1">
            <h6>Browser Distribution</h6>

            <h6 class="text--disabled font-weight-light">Top 8 Brands</h6>
        </v-card-title>


        <v-divider></v-divider>

        <v-row v-if="browser_distribution.length">
            <v-col class="d-flex justify-center align-center" cols="6">
                <v-card-text class="py-0">
                    <v-card-subtitle class="black--text text-caption">

                        <div v-for="(item, index) in labelData" :key="index" class="d-flex align-center">
                            <v-icon :color="chartOptions?.colors[index]" left x-small>mdi-circle</v-icon>{{ `${item?.deviceName}: ${item?.count?.toLocaleString()} - (${item?.percentage})` }}
                        </div>

                    </v-card-subtitle>
                </v-card-text>
            </v-col>

            <v-col cols="6">
                <apexchart height="250" type="pie" :options="chartOptions" :series="series"></apexchart>
            </v-col>
        </v-row>

        <div v-else>
            <v-card height="255" flat class="d-flex align-center justify-center">
                <div class="text-center py-10">
                    <v-icon x-large>mdi-alert-circle-outline</v-icon>
                    <p class="text-caption text-disabled">No data available</p>
                </div>
            </v-card>
        </div>

    </v-card>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            series: [],
            labelData: [],
            chartOptions: {
                chart: {
                    width: '100%',
                    type: 'pie',
                },
                colors:[ '#00E396', '#f3b415', '#f27036','#d36085', '#6092c0', '#7e2553', '#2a2553', '#2a2'],
                labels: [],
                theme: {
                    monochrome: {
                        enabled: false
                    }
                },
                plotOptions: {
                    pie: {
                        dataLabels: {
                            offset: -5
                        }
                    }
                },
                dataLabels: {
                    formatter(val, opts) {
                        const name = opts.w.globals.labels[opts.seriesIndex]
                        return [name, val.toFixed(1) + '%']
                    }
                },
                legend: {
                    show: false
                }
            },
        }
    },
    props: {
        campaign: {
            type: String,
            validator: function (value) {
                return Number(value);
            }
        },
    },

    watch: {
        browser_distribution: {
            handler: function (val) {

                //get the top 8 from val
                val = val.slice(0, 8);
                
                const totalCount = val.reduce((total, bucket) => total + bucket.doc_count, 0);

                //get the device names
                const deviceNames = val.map((bucket) => bucket.key);

                //get the count of each device
                const deviceCounts = val.map((bucket) => bucket.doc_count);

                //get the percentage of each device
                const devicePercentages = val.map((bucket) => ((bucket.doc_count / totalCount) * 100).toFixed(2) + "%");

                //set the series
                this.series = deviceCounts;

                //set the label data
                this.labelData = val.map((bucket, index) => ({
                    deviceName: bucket.key,
                    count: bucket.doc_count,
                    percentage: devicePercentages[index]
                }));
                
                // //set that to chart options
                this.chartOptions = {
                    ...this.chartOptions,
                    labels: deviceNames
                };
            },
            deep: true
        },
    },
    mounted() {
        this.getBrowserDistribution();
    },

    computed: {
        browser_distribution() {
            return this.$store.state.dsp.browser_distribution
        }
    },

    methods: {
        //Get top carriers
        async getBrowserDistribution() {

            this.loading = true;

            try {

                let body = {
                    "campaign": this.campaign,
                    "body": {
                        "size": 0,
                        "query": {},
                        "aggs": {
                            "test_histogram": {
                                "terms": {
                                    "field": "r_device.sua.browsers.brand.keyword",
                                    "size": 10
                                }
                            }
                        }
                    }
                }

                await this.$store.dispatch("dsp/getBrowserDistribution", body);

            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
    }
}
</script>