<template>
    <div>
        <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
export default {
    data() {
        return {
            series: [{
                data: []
            }],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 350,
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: [],
                    labels: {
                        formatter: function (val) {
                            //add commas to numbers greater than 1000 and two decimal places for float numbers
                            
                            if(val > 1000){
                                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            }
                            else if(val % 1 != 0){
                                return val.toFixed(2);
                            }
                            else{
                                return val;
                            }

                        }
                    }
                }
            },
        }
    },
    //props
    props: {
        data: {
            required: true,
            type: Array,
            default: () => []
        },
        type: {
            type: String,
            required: true,
        }
    },

    watch: {
        data: {
            handler: function (val) {
                let data = val.map((item) => {
                    return item.data
                })

                let categories = val.map((item) => {
                    return item.size
                })

                this.series = [{
                    name: this.type,
                    data: data
                }]

                this.chartOptions = {
                    ...this.chartOptions, ...{
                        xaxis: {
                            categories: categories
                        }
                    }
                }
            },
            deep: true
        },
    },

    methods: {

    }
}
</script>