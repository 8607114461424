<template>
  <v-card flat height="300">
    <v-card-title class="d-flex align-center justify-space-between py-1">
      <h6>Fastest Growing Models</h6>

      <h6 class="text--disabled font-weight-light">Top 5 Models</h6>
    </v-card-title>

    <v-divider></v-divider>

    <v-row>
      <v-col cols="12">
        <apexchart
          height="250"
          type="bar"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </v-col>
    </v-row>

    <div v-if="false">
      <v-card height="255" flat class="d-flex align-center justify-center">
        <div class="text-center py-10">
          <v-icon x-large>mdi-alert-circle-outline</v-icon>
          <p class="text-caption text-disabled">No data available</p>
        </div>
      </v-card>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      series: [
        {
          name: "Growth",
          data: [44, 55, 41, 67, 22],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            columnWidth: "50%",
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val + "%"
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        stroke: {
          width: 0,
        },
        grid: {
          row: {
            colors: ["#fff", "#f2f2f2"],
          },
        },
        xaxis: {
          labels: {
            rotate: -45,
          },
          categories: [
            "Galaxy S10",
            "iPhone 11",
            "OnePlus 7 Pro",
            "Google Pixel 4",
            "Huawei P30 Pro",
          ],
          tickPlacement: "on",
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            type: "horizontal",
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 0.85,
            opacityTo: 0.85,
            stops: [50, 0, 100],
          },
        },
      },
    }
  },
}
</script>
