<template>
    <v-card flat height="300">
        <v-card-title class="d-flex align-center py-1">
            <h6>Device Types</h6>
        </v-card-title>

        <v-divider></v-divider>

        <v-row v-if="device_types.length">
            <v-col class="d-flex justify-center align-center" cols="6">
                <v-card-text>
                    <v-card-subtitle class="black--text text-caption">

                        <div v-for="(item, index) in labelData" :key="index" class="d-flex align-center">
                            <v-icon :color="chartOptions?.colors[index]" left x-small>mdi-circle</v-icon>{{ `${item?.deviceName}: ${item?.count?.toLocaleString()} - (${item?.percentage})` }}
                        </div>

                    </v-card-subtitle>
                </v-card-text>
            </v-col>

            <v-col cols="6">
                <apexchart height="250" type="pie" :options="chartOptions" :series="series"></apexchart>
            </v-col>
        </v-row>

        <div v-else>
            <v-card height="255" flat class="d-flex align-center justify-center">
                <div class="text-center py-10">
                    <v-icon x-large>mdi-alert-circle-outline</v-icon>
                    <p class="text-caption text-disabled">No data available</p>
                </div>
            </v-card>
        </div>

    </v-card>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            series: [],
            labelData: [],
            devices: [
                { code: 1, name: 'Mobile/Tablet' },
                { code: 2, name: 'Personal Computer' },
                { code: 3, name: 'Connected TV' },
                { code: 4, name: 'Phone' },
                { code: 5, name: 'Tablet' },
                { code: 6, name: 'Connected Device' },
                { code: 7, name: 'Set Top Box' }
            ],
            chartOptions: {
                chart: {
                    width: '100%',
                    type: 'pie',
                },
                colors:[ '#00E396', '#f3b415', '#f27036','#d36085', '#6092c0', '#7e2553'],
                labels: [],
                theme: {
                    monochrome: {
                        enabled: false
                    }
                },
                plotOptions: {
                    pie: {
                        dataLabels: {
                            offset: -5
                        }
                    }
                },
                dataLabels: {
                    formatter(val, opts) {
                        const name = opts.w.globals.labels[opts.seriesIndex]
                        return [name, val.toFixed(1) + '%']
                    }
                },
                legend: {
                    show: false
                }
            },
        }
    },
    props: {
        campaign: {
            type: String,
            validator: function (value) {
                return Number(value);
            }
        },
    },

    watch: {
        device_types: {
            handler: function (val) {
                
                const totalCount = val.reduce((total, bucket) => total + bucket.doc_count, 0);

                const result = val.map(bucket => {
                    const device = this.devices.find(device => device.code === bucket.key);
                    const deviceName = device ? device.name : 'Unknown';
                    const percentage = (bucket.doc_count / totalCount) * 100;

                    return { deviceName, count: bucket.doc_count, percentage: percentage.toFixed(2) + '%' };

                });

                this.labelData = result;

                const deviceNames = result.map(item => item.deviceName);
                this.series = result.map(item => item.count);


                // //set that to chart options
                this.chartOptions = {
                    ...this.chartOptions,
                    labels: deviceNames
                };
            },
            deep: true
        },
    },
    mounted() {
        this.getDeviceTypes();
    },

    computed: {
        device_types() {
            return this.$store.state.dsp.device_types
        }
    },

    methods: {
        //Get top carriers
        async getDeviceTypes() {

            this.loading = true;

            try {

                let body = {
                    "campaign": this.campaign,
                    "body": {
                        "size": 0,
                        "query": {},
                        "aggs": {
                            "test_histogram": {
                                "terms": {
                                    "field": "r_device.devicetype",
                                    "size": 10
                                }
                            }
                        }
                    }
                }

                await this.$store.dispatch("dsp/getDeviceTypes", body);

            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
    }
}
</script>