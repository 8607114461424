<template>
    <div>
        <apexchart height="300" type="donut" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
export default {
    data() {
        return {
            series: [44, 55, 41, 17, 15],
            chartOptions: {
                chart: {
                    width: "100%",
                    type: 'donut',
                },
                legend: {
                    show: true,
                    floating: true,
                    fontSize: '14px',
                    position: 'left',
                    offsetX: -20,
                    offsetY: -8,
                    labels: {
                        useSeriesColors: false,
                    },
                    markers: {
                        size: 0
                    },
                    itemMargin: {
                        vertical: 3
                    }
                },
                plotOptions: {
                    pie: {
                        donut: {
                            size: "50%"
                        }
                    }
                },
                colors: ['#77c1ab', '#6092c0', '#d36085', '#ffb01a', '#00E396', '#80c7fd'],
                labels: ['Android 7.0', 'Android 8.0', 'Android 9.0', 'Android 10.0', 'Android 11.0'],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },
        }
    },
    //props
    // props: {
    //     color: {
    //         type: String,
    //         default: "#80CBC4"
    //     },
    //     data: {
    //         type: Array,
    //         default: () => []
    //     }
    // },

    // watch: {
    //     data: {
    //         handler: function (val) {
    //             this.series = [{
    //                 data: val,
    //             }]
    //         },
    //         deep: true
    //     }
    // },

    methods: {

    }
}
</script>