<template>
  <v-card flat height="300">
    <v-card-title class="d-flex align-center justify-space-between py-1">
      <h6>Users by Sim Count</h6>
    </v-card-title>

    <v-divider></v-divider>

    <v-row>
      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Gained</th>
                <th class="text-left">Lost</th>
                <th class="text-left">Balance</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <td>
                  <div class="d-flex">
                    <v-icon :color="item?.gained_color" left
                      >mdi-triangle-small-up</v-icon
                    >
                    <span>{{ item?.gained }}</span>
                  </div>
                </td>
                <td>
                  <div class="d-flex">
                    <v-icon :color="item?.lost_color" left
                      >mdi-triangle-small-up</v-icon
                    >
                    <span>{{ item?.lost }}</span>
                  </div>
                </td>
                <td class="border-red">
                  <div class="d-flex">
                    <v-icon :color="item?.balance_color" left
                      >mdi-triangle-small-up</v-icon
                    >
                    <span>{{ item?.balance }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <div v-if="false">
      <v-card height="255" flat class="d-flex align-center justify-center">
        <div class="text-center py-10">
          <v-icon x-large>mdi-alert-circle-outline</v-icon>
          <p class="text-caption text-disabled">No data available</p>
        </div>
      </v-card>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      items: [
        {
          gained: "12,353",
          gained_color: "green",
          lost: "245,656",
          lost_color: "red",
          balance: "18,009",
          balance_color: "green",
        },
        {
          gained: "394,244",
          gained_color: "orange",
          lost: "254,425",
          lost_color: "red",
          balance: "34,646",
          balance_color: "green",
        },
        {
          gained: "123,231",
          gained_color: "green",
          lost: "12,345",
          lost_color: "green",
          balance: "12,345",
          balance_color: "green",
        },
        {
          gained: "160,000",
          gained_color: "red",
          lost: "135,425",
          lost_color: "red",
          balance: "25,575",
          balance_color: "green",
        },
      ],
    }
  },
}
</script>
