<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card :loading="loading">
        <v-toolbar dark color="primary" flat class="pl-0">
          <v-toolbar-title class="text-h6 white--text">{{
            campaign.campaign.name
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon @click="dismiss">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="data"
                  outlined
                  dense
                  :rules="viewRules"
                  :label="
                    campaign?.campaign?.campaign_attribute?.campaign_type ===
                    'video'
                      ? 'Enter the number of views'
                      : 'Engagement'
                  "
                  required
                  class="mt-5"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dismiss"> Close </v-btn>
          <v-btn
            color="blue-grey ml-3"
            depressed
            :disabled="isDisabled()"
            small
            class="white--text"
            @click="updateAction"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    campaign: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loading: true,
    data: null,
    viewRules: [(v) => !!v || "This field is required"],
  }),
  methods: {
    dismiss() {
      this.$emit("dismiss");
    },
    updateAction() {
      if (
        this.campaign?.campaign?.campaign_attribute?.campaign_type === "video"
      ) {
        this.updateViews();
      } else if (
        this.campaign?.campaign?.campaign_attribute?.campaign_type ===
        "engagement"
      ) {
        this.updateEngagement();
      }
    },
    updateViews() {
      let body = {
        campaign_id: this.campaign.campaign.id,
        views: this.data,
      };
      this.$emit("updateViews", body);
      this.dismiss();
    },
    updateEngagement() {
      let body = {
        campaign_id: this.campaign.campaign.id,
        engagements: this.data,
      };
      this.$emit("updateEngagement", body);
      this.dismiss();
    },
    isDisabled() {
      if (this.data) {
        return false;
      }
      return true;
    },
  },
};
</script>
