<template v-slot:extension>
  <v-btn color="success" :href="url" download depressed :disabled="isDisabled">
    <v-icon left>mdi-download</v-icon>
    Download Lead Stats
  </v-btn>
</template>

<script>
export default {
  props: ["url", "isDisabled"],
  data() {
    return {
      is_disabled: false,
    };
  },
  watch: {
    isDisabled() {
      this.is_disabled = this.isDisabled;
    },
  },
};
</script>

