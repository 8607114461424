<template>
    <div>
        <v-card height="300" id="container" flat>

            <v-card-title class="d-flex align-center py-1">
                <h6 class="mr-auto">Device Models</h6>
            </v-card-title>

            <v-divider></v-divider>

            <div v-if="device_models.length">
                <v-card-text>
                    <apexchart type="bar" height="15" :options="chartOptions" :series="series"></apexchart>
                </v-card-text>
                <v-card-subtitle class="black--text text-caption py-0">
                    <div v-for="(item, index) in series" :key="index" class="d-flex justify-space-between align-center">
                        <div>
                            <v-icon left x-small :color="colors[index]">mdi-circle</v-icon>
                            {{ item?.name }}
                        </div>
                        <div>
                            {{ `${item?.data}%` }}
                        </div>
                    </div>
                </v-card-subtitle>
            </div>

            <div v-else>
                <v-card height="255" flat class="d-flex align-center justify-center">
                    <div class="text-center py-10">
                        <v-icon x-large>mdi-alert-circle-outline</v-icon>
                        <p class="text-caption text-disabled">No data available</p>
                    </div>
                </v-card>
            </div>

        </v-card>


    </div>
</template>

<script>
export default {
    data() {
        return {
            series: [],
            colors: ["blue", "purple", "red", "orange", "yellow", "green"],
            chartOptions: {
                chart: {
                    type: "bar",
                    stacked: true,
                    stackType: "100%",
                    sparkline: {
                        enabled: true,
                    },
                },
                colors: [
                    "#2196F3",
                    "#9c27b0",
                    "#F44336",
                    "#ff9800",
                    "#ffeb3b",
                    "#4CAF50",
                    "#FF5722",
                    "#795548",
                    "#607d8b",
                ],
                labels: ['App', 'Web'],
                plotOptions: {
                    bar: {
                        horizontal: true,
                    },
                },
                stroke: {
                    width: 1,
                    colors: ["#fff"],
                },

                xaxis: {
                    categories: ["Model"],
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val.toFixed(2) + "%";
                        },
                    },
                },
            },
        }
    },
    props: {
        campaign: {
            type: String,
            validator: function (value) {
                return Number(value);
            }
        },
    },

    watch: {
        device_models: {
            handler: function (val) {

                const totalDocCount = val.reduce((acc, item) => acc + item.doc_count, 0);

                let data = val.map((item) => {
                    return {
                        name: item.key,
                        data: [((item.doc_count / totalDocCount) * 100).toFixed(2)]
                    }
                });

                this.series = data;

                this.series.sort((a, b) => b.data[0] - a.data[0]);
                
                //get the top 8
                this.series = this.series.slice(0, 9);
            },
            deep: true
        },
    },
    mounted() {
        this.getDeviceModels();
    },

    computed: {
        device_models() {
            return this.$store.state.dsp.device_model_distribution;
        }
    },

    methods: {
        //Get device models
        async getDeviceModels() {

            this.loading = true;

            try {

                let body = {
                    "campaign": this.campaign,
                    "body": {
                        "size": 0,
                        "query": {},
                        "aggs": {
                            "test_histogram": {
                                "terms": {
                                    field: "r_device.model.keyword",
                                    "size": 100
                                }
                            }
                        }
                    }
                }

                await this.$store.dispatch("dsp/getDeviceModelDistribution", body);

            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
    }
}
</script>