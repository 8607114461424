<template>
    <div>
        <apexchart type="radialBar" height="250" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
export default {
    data() {
        return {
            series: [this.data],
            chartOptions: {
                chart: {
                    height: 250,
                    type: 'radialBar',
                    width: "100%",
                    parentHeightOffset: 0,
                    toolbar: { show: false },
                    sparkline: { enabled: true },
                },
                labels: ['Progress'],
                colors: [this.colors],
            },
        }
    },
    //props
    props: {
        data: {
            type: String,
            default: () => 0
        },
        colors: {
            type: String,
        }
    },

    watch: {
        data: {
            handler: function (val) {
                this.series = [val]

                this.chartOptions = {
                    ...this.chartOptions, ...{
                       colors: [this.__progressColor]
                    }
                }

                console.log("hi")
            },
            deep: true
        },
        colors: {
            handler: function (val) {
                this.chartOptions = {
                    ...this.chartOptions, ...{
                       colors: [val]
                    }
                }
            },
            deep: true
        }
    },

    methods: {

    }
}
</script>